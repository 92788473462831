import { useEffect } from "react";
import { AlphanumericField } from "../../../../utils/form";
import _ from "lodash";
import { useWatch } from 'react-hook-form';

const InsuranceNumberInput = ({ form }) => {
  const { control, setValue } = form;
  const healthInsurance = useWatch({
    control,
    name: "healthInsurance",
    defaultValue: "",
  });

  const isParticular = _.lowerCase(healthInsurance) == "particular";

  useEffect(() => {
    if (isParticular) {
      setValue("insuranceNumber", "");
    }
  }, [isParticular, setValue]);

  return (
    <AlphanumericField
      control={control}
      fullWidth
      name="insuranceNumber"
      label="Número de afiliado"
      variant="outlined"
      required={!isParticular}
      disabled={isParticular}
    />
  );
}

export default InsuranceNumberInput;