import config from "../config";
import BearerApi from "./bearer";
import _ from "lodash";
import Promise from "bluebird";
import dataURItoBlob from "./dataUriToBlob";
import { flatten } from 'flat';

const baseUrl = config.apiUrl;
class Api extends BearerApi {
  constructor(token) {
    super(baseUrl, token);
  }
  
  login(email, password) {
    return this.post("/auth/login", { email, password });
  }

  me() {
    return this.get("/users/me");
  }

  createUser(userToCreate) {
    const body = new FormData();
    _(_.omit(userToCreate, "signature")).each((value, key) => body.append(key, value));
    const signatureFile = this._toSignatureFile(userToCreate.signature);
    body.append("signature", signatureFile);
    return this.post("/users", body);
  }

  createMedicalDocument(type, body) {
    return this.post(`/${type}`, body);
  }

  prescriptionPdf(id) {
    return this.get(`/prescriptions/${id}/pdf`, {}, { responseType: 'blob' });
  }

  patients() {
    return this.get("/patients");
  }

  share(basePath, id, body) {
    return this.post(`/${basePath}/${id}/share`, body);
  }

  templates(type) {
    return this.get("/templates", { type });
  }

  createTemplate(type, templateDto) {
    const body = _.assign({}, templateDto, { type });
    return this.post("/templates", body);
  }

  updateTemplate(id, body) {
    return this.put(`/templates/${id}`, body);
  }

  deleteTemplate(id) {
    return this.delete(`/templates/${id}`);
  }

  requestPasswordRecovery(email) {
    return this.post("/passwordRecovery/request", { email });
  }

  changePassword(body) {
    return this.post("/passwordRecovery/change", body);
  }

  medicalDocuments(basePath, { filters, page, rowsPerPage }) {
    const filter = _(filters).pickBy().thru(filter => flatten({ filter })).value();
    return this.get(`/${basePath}`, { ...filter, page: page + 1, limit: rowsPerPage });
  }

  repeat(basePath, id, body) {
    return this.post(`/${basePath}/${id}/repeat`, body);
  }

  createPatient(patientDto) {
    const body = _.omit(patientDto, "id");
    return this.post("/patients", body);
  }

  updateUser(body) {
    const { signature, ...userInfo } = body;
    return Promise.all([this.updateUserInfo(userInfo), this.updateUserSignature(signature)]);
  }

  updateUserInfo(body) {
    if (_.isEmpty(body)) return Promise.resolve();
    return this.put("/users/me", body);
  }

  updateUserSignature(signature) {
    if (!signature) return Promise.resolve();
    const signatureFile = this._toSignatureFile(signature);
    const formData = new FormData();
    formData.append("signature", signatureFile);
    return this.put("/users/me/signature", formData);
  }

  signature() {
    return this.get("/users/me/signature");
  }

  patientRegistrationToken() {
    return this.get("/users/me/patientsToken");
  }

  userByToken() {
    return this.get("/users/byPatientsToken");
  }

  _toSignatureFile(signatureDataUri) {
    const blob = dataURItoBlob(signatureDataUri)
    const signatureFile = new File([blob], "signature.jpg", { type: "image/jpeg" })
    return signatureFile;
  }
}

export default Api;
