import { useEffect } from "react";
import _ from "lodash";
import { SelectWithFilter } from "../../../../../utils/form";
import { matchSorter } from "match-sorter";

const defaultHelperText = "Escribí el apellido y seleccioná el paciente de la lista. Si no está, completá los datos.";

const SurnameInput = ({ form: { control, setValue, reset, getValues, clearErrors }, patient, patients, helperText = defaultHelperText, ...props }) => {
  const onPicked = patient => {
    clearErrors();

    if (_.isEmpty(patient)) {
      const patientWasPicked = getValues("id");
      if (patientWasPicked) reset();
      return;
    }

    const { id, name, surname, documentNumber, healthInsurance, insuranceNumber, email, phone, gender, birthDate } = patient;

    setValue("id", id);
    setValue("name", name);
    setValue("surname", surname);
    setValue("documentNumber", documentNumber);
    setValue("healthInsurance", _.toUpper(healthInsurance));
    setValue("insuranceNumber", insuranceNumber);
    setValue("email", email);
    setValue("phone", phone);
    setValue("gender", gender);
    setValue("birthDate", birthDate);
  }

  useEffect(() => {
    onPicked(patient);
  }, [patient]);
  
  return (
    <>
      <SelectWithFilter
        control={control}
        required
        name="surname"
        label="Apellido"
        list={patients}
        helperText={helperText}
        onPicked={onPicked}
        getOptionLabel={patient => {
          if (!_.isObject(patient)) return patient;
          const { name, surname, documentNumber } = patient;

          return `${surname}, ${name} - ${documentNumber}`;
        }}
        matcherOptions={{ keys: ['name', 'surname', 'documentNumber', 'insuranceNumber'], threshold: matchSorter.rankings.CONTAINS }}
        minInputLength={3}
        {...props}
      />
      <input type="hidden" name="id" />
    </>
  );
};

export default SurnameInput;