import _ from "lodash";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Api from "../../apis/api";
import { Alert, ErrorAlert, LoadingSpinner } from "../../utils";
import PatientCreator from "../utils/patient/new/PatientCreator";
import { Box, Typography } from "@mui/material";

const InvalidTokenError = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw' }} >
      <Typography variant="h6" align="center">
        El link ingresado es inválido, por favor volvé a pedírselo a tu médico/a
      </Typography>
    </Box>
  );
};

const PatientSelfRegister = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const api = new Api(token);

  const [user, setUser] = useState();
  const { title, name, surname } = user || { title: "", name: "", surname: "" };
  const fromDoctorByTitle = title === "Dr" ? `del ${title}.` : `de la ${title}.`;
  const patientCreatorTitle = `Registrate como paciente ${fromDoctorByTitle} ${name} ${surname}`

  const [doctorLoading, setDoctorLoading] = useState(true);
  const [creationStatus, setCreationStatus] = useState(null);
  const succeeded = creationStatus === "succeeded";
  const failed = creationStatus === "failed";
  const creationLoading = creationStatus === "loading";
  const invalidToken = !token || (!doctorLoading && _.isEmpty(user));

  const failedMessage = "No pudimos registrarte. Si el error vuelve a ocurrir, pedile ayuda a tu médico/a"
  const successMessage = "Listo! Ya estás registrado. Avisale al médico para que pueda empezar a recetarte.";
  const onAlertClose = () => setCreationStatus(null);

  useEffect(() => {
    if (!token) {
      setDoctorLoading(false);
      return;
    }
    api.userByToken()
      .then(setUser)
      .tap(() => setDoctorLoading(false))
      .catch(() => setDoctorLoading(false));
  }, []);

  const onSubmit = patient => {
    setCreationStatus("loading");
    api.createPatient(patient)
      .then(() => setCreationStatus("succeeded"))
      .catch(() => setCreationStatus("failed"))
  };

  if (doctorLoading) return <LoadingSpinner isLoading={doctorLoading} absolute />;

  if (invalidToken) return <InvalidTokenError />;

  return (
    <>
      <PatientCreator onSubmit={onSubmit} loading={creationLoading} title={patientCreatorTitle} contactRequired/>
      <ErrorAlert show={failed} message={failedMessage} duration={10000} onClose={onAlertClose} />
      <Alert severity="success" message={successMessage} show={succeeded} duration={10000} onClose={onAlertClose} />
    </>
  );
};

export default PatientSelfRegister;