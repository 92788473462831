import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import NavigationBar from "../../../../../utils/wizard/navigation";
import { DatePicker } from "../../../../../utils/form";
import moment from "moment";

const defaultValues = {
  date: moment().format("YYYY-MM-DD"),
};

const DateForm = ({ onFinish, ...props }) => {
  const form = useForm({ defaultValues });
  const { handleSubmit } = form;
  const minDate = moment();
  
  return (
    <form onSubmit={handleSubmit(onFinish)} autoComplete="off">
      <Typography variant="h5" gutterBottom>Fecha</Typography>
      <DatePicker form={form} name="date" label="Fecha" required minDate={minDate} />
      <NavigationBar step={4} {...props} />
    </form>
  );
};

export default DateForm;