import * as yup from "yup";
import { text as textSchema, number as numberSchema, signature as signatureSchema } from "../schemas";
import _ from "lodash";

const doctorValidations = {
  name: textSchema,
  surname: textSchema,
  documentNumber: numberSchema,
  licenseType: textSchema
    .default("nacional"),
  licenseNumber: numberSchema,
  title: textSchema
    .default("Dr"),
  specialty: yup.string()
    .required("")
    .default(""),
  workPhone: textSchema,
  address: textSchema,
  province: textSchema,
  signature: signatureSchema,
};

export default doctorValidations;