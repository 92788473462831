import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Controller } from "react-hook-form";
import _ from "lodash";

const useStyles = makeStyles(theme => ({
  root: {
    whiteSpace: "unset",
    wordBreak: "break-all"
  }
}));

const CustomSelect = ({ control, name, label, list, valueTextGetter = _.identity, required, disabled }) => {
  const classes = useStyles();
  const labelId = `${name}-label`;
  const labelWithRequired = `${label}${required ? "*" : ""}`;
  
  return <FormControl fullWidth>
    <Controller
      control={control}
      name={name}
      rules={{
        required
      }}
      defaultValue=""
      render={({
        field,
        fieldState: { error }
      }) => (
        <>
          <InputLabel id={labelId} error={!_.isEmpty(error)}>{labelWithRequired}</InputLabel>
          <Select
            id={`${name}-select`}
            labelId={labelId}
            label={labelWithRequired}
            name={name}
            error={!_.isEmpty(error)}
            disabled={disabled || _.isEmpty(list)}
            {...field}
          >
            {
              _.map(list, (element, index) => <MenuItem className={classes.root} key={index} value={element}>{valueTextGetter(element)}</MenuItem>)  
            }
          </Select>
        </>
      )}
    />
  </FormControl>
};

export default CustomSelect;