import { useCallback, useEffect, useState } from "react";
import { Button, Dialog, DialogTitle, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { ErrorAlert, Alert } from "../../../utils";
import { TextField } from "../../../utils/form";
import _ from "lodash";
import UrlShortenerApi from "../../../apis/urlShortenerApi";
import { useApi, useIsInQuickLink } from "../../../utils/hooks";
import emailsRegex from "../../../utils/emailsRegex";

const phoneMethod = {
  destinationLabel: "Número de teléfono",
  validationPattern: /^(?=.*[0-9])[- +()0-9]+$/,
  valueGetter: ({ patient: { phone } }) => phone
};

const methods = [
  {
    value: "print",
    label: "Ver PDF",
  },
  {
    value: "email",
    label: "Email",
    destinationLabel: "Dirección de correo",
    validationPattern: emailsRegex,
    valueGetter: ({ patient: { email } }) => email,
    helper: "Para enviar a más de un destinatario, separarlos con coma (,)"
  },
  {
    value: "whatsapp",
    label: "Whatsapp propio",
    ...phoneMethod
  },
  {
    value: "ownWhatsapp",
    label: "Whatsapp de Recetario (Nuevo!)",
    helper: "Con código de área",
    ...phoneMethod
  },
];

//TODO avoid repeating typeName everywhere
const toWhatsappShareText = ({ type }, url) => {
  const typeName = type == "prescription" ? "receta" : "orden";

  return (`
Aquí podrá descargar su ${typeName}: ${url}
Recetario.com.ar
`);
}

//TODO reuse MethodPicker
const ExporterOptions = ({ method, setMethod, form, medicalDocument }) => {
  const fullMethod = _.find(methods, { value: method });
  const handleChange = (event) => {
    const newMethod = event.target.value;
    setMethod(newMethod);
  };
  
  useEffect(() => {
    const { valueGetter } = fullMethod;
    if (!valueGetter) return;
    const value = valueGetter(medicalDocument);
    form.reset({ destination: value || "" });
  }, [method]);

  return (
    <FormControl style={{ padding: "1rem", boxSizing: "border-box", alignItems: "center", width: "100%" }}>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        defaultValue="manual"
        row
        value={method}
        onChange={handleChange}
      >
        <Stack direction="column">
        {
          _.map(methods, ({ value, label, disabled }) => (
            <FormControlLabel control={<Radio />} value={value} label={label} disabled={disabled} key={value} />
          ))
        }
        </Stack>
      </RadioGroup>
      {method !== "print" && (
        <div style={{ paddingInline: "2rem", marginTop: "1rem" }}>
          <TextField
            name="destination"
            required
            control={form.control}
            fullWidth
            label={fullMethod.destinationLabel}
            rules={{ required: true, pattern: { value: fullMethod.validationPattern } }}
            helperText={fullMethod.helper}
          />
        </div>
      )}
    </FormControl>
  )
};

const Finish = ({ onClose }) => {
  const isInQuickLink = useIsInQuickLink();

  return isInQuickLink
    ? <Typography>Una vez que compartas el documento, podrás cerrar esta ventana.</Typography>
    : <Button variant="contained" onClick={onClose}>Finalizar</Button>;
};

const Exporter = props => {
  const { medicalDocument } = props;
  const [method, setMethod] = useState("print");
  const form = useForm({ defaultValues: { destination: '' } });
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);
  const [shared, setShared] = useState(false);
  const { id } = medicalDocument;
  const api = useApi();
  
  //TODO avoid repeating typeName and plural for path's
  const typeName = medicalDocument.type == "prescription" ? "receta" : "orden";
  const medicalDocumentUrl = medicalDocument.url;

  const showDocument = () => {
    window.open(medicalDocumentUrl, '_blank', 'noreferrer');
  };

  const shareInWhatsapp = (phoneNumber, text) => {
    const whatsappNumber = _.replace(phoneNumber, /[^0-9.]/g, '');
    window.open(`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(text)}`, '_blank', 'noreferrer');
  };
  
  const { handleSubmit } = form;
  const onGenerate = useCallback(({ destination }) => {
    switch (method) {
      case "print":
        showDocument();
        break
      case "email":
        setLoading(true);
        api.share(`${medicalDocument.type}s`, id, { method: "email", destination })
        .tap(() => {
          setLoading(false);
          setShared(true);
        })
        .catch(() => {
          setFailed(true);
          setLoading(false);
        });
        break
      case "whatsapp":
        setLoading(true);
        new UrlShortenerApi().shorten(medicalDocumentUrl)
        .catch(() => medicalDocumentUrl)
        .then(shortenedUrl => {
          const text = toWhatsappShareText(medicalDocument, shortenedUrl);
          setLoading(false);
          shareInWhatsapp(destination, text);
        })
        .catch(() => {
          setFailed(true);
          setLoading(false);
        });
        break
      case "ownWhatsapp":
        setLoading(true);
        api.share(`${medicalDocument.type}s`, id, { method: "whatsapp", destination })
        .tap(() => {
          setLoading(false);
          setShared(true);
        })
        .catch(() => {
          setFailed(true);
          setLoading(false);
        });
        break
      default:
        break;
    }
  }, [method]);

  return (
    <>
      <Dialog open={true}>
        <form onSubmit={handleSubmit(onGenerate)} autoComplete="off">
          <DialogTitle>Exportar {typeName} {id}</DialogTitle>
          <ExporterOptions method={method} setMethod={setMethod} onGenerate={onGenerate} form={form} medicalDocument={medicalDocument} />
          <Stack p="1rem" spacing="1rem">
            <LoadingButton variant="contained" type="submit" loading={loading}>Generar {typeName}</LoadingButton>
            <Finish {...props} />
          </Stack>
          </form>
      </Dialog>
      <ErrorAlert
        show={failed}
        onClose={() => setFailed(false)}
        message="No se pudo compartir el documento. Intentá de nuevo o contactanos a consultas@recetario.com.ar"
      />
      <Alert
        severity="success"
        message="Listo! Ya compartimos el documento"
        onClose={() => setShared(false)}
        show={shared}
      />
    </>
  )
}

export default Exporter;