import QRCode from "react-qr-code";
import "./index.css";
import { Stack, Typography } from "@mui/material";
import Logo from "../../../../utils/Logo";

const PrintableQr = ({ url, reference }) => {

  return (
    <div className="printableLayout" ref={reference}>
      <Stack spacing="2rem" style={{ alignItems: "center", justifyContent: "center", maxWidth: "500px" }}>
        <Logo width="280px" />
        <Typography variant="h4" textAlign="center">
          Escaneá el código para que tu doctor pueda hacerte recetas y órdenes
        </Typography>
        <div className="printableContainer">
          <QRCode style={{ width: "100%", height: "auto" }} value={url} />
        </div>
      </Stack>
    </div>
  );
}

export default PrintableQr;