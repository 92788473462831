import axios from "axios";
import Promise from "bluebird";
import _ from "lodash";

class BaseApi {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  get(path, query, options) {
    return this._request("get", path, query, null, options);
  }

  post(path, body, options) {
    return this._request("post", path, {}, body, options);
  }

  delete(path, options) {
    return this._request("delete", path, {}, null, options);
  }

  put(path, body, options) {
    return this._request("put", path, {}, body, options);
  }
  
  _request(method, path, query, body, customOptions) {
    const options = this._options(method, path, query, body, customOptions);
    return Promise.resolve(axios(options))
    .get("data");
  }

  _options(method, path, query, body, customOptions) {
    return _.merge({
      url: this._url(path),
      method,
      params: query,
      data: body,
      },
      customOptions
    )
  }

  _url(path) {
    return `${this.baseUrl}${path}`;
  }
}

export default BaseApi;