import { RouterProvider } from 'react-router-dom';
import router from "../navigation/router";
import { Grid, ThemeProvider } from "@mui/material";
import theme from './theme';
import './App.css';
import { EventsProxyProvider } from '../utils/context/eventsProxy/context';
import { QueryClient, QueryClientProvider, } from '@tanstack/react-query'

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <EventsProxyProvider>
          <Grid container
            spacing={0}
            alignItems="start"
            justifyContent="center"
            bgcolor="#FFFFFF"
            height="100%"
          >
            <RouterProvider router={router} />
          </Grid>
        </EventsProxyProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
