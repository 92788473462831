import { FormControl, FormControlLabel,Radio, RadioGroup } from "@mui/material";
import _ from "lodash";

const defaultOptions = [{ label: "Vademecum",  value: "vademecum" }, { label: "Manual", value: "manual" }];

const MethodPicker = ({ method, setMethod, options = defaultOptions, ...props }) => {
  const handleChange = (event) => {
    setMethod(event.target.value);
  };

  return (
    <FormControl {...props}>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        defaultValue="manual"
        row
        value={method}
        onChange={handleChange}
      >
        {
          _.map(options, ({ label, value, disabled }, i) => (
            <FormControlLabel key={i} value={value} control={<Radio />} label={label} disabled={disabled} />
        ))
        }
      </RadioGroup>
    </FormControl>
  );
};

export default MethodPicker;