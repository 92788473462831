import _ from 'lodash';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableHead from '@mui/material/TableHead';

function CustomTableHead({ sortingField, onRequestSort, rows }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {_.map(
          rows,
          (row) => {
            return (
              <TableCell
                key={row.id}
                align={row.align}
                padding={row.disablePadding ? 'none' : 'normal'}
                sortDirection={sortingField.id === row.id ? sortingField.direction : false}
                style={{ backgroundColor: '#E0E0E0', fontWeight: 'bold' }}
              >
                {row.sort
                  ? (
                    <TableSortLabel
                      active={sortingField.id === row.id}
                      direction={sortingField.direction}
                      onClick={createSortHandler(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  )
                  : row.label
                }
              </TableCell>
            );
          },
          this
        )}
      </TableRow>
    </TableHead>
  );
}

export default CustomTableHead;