
import { SelectWithFilter } from "../../../../../utils/form";
import _ from "lodash";
import diagnosisList from "./diagnosisList.json";
import { matchSorter } from "match-sorter";

const helperText = "Escribí el nombre o código de diagnóstico";

const toDiagnosisLabel = ({ code, name }) => `${code} - ${name}`;

const DiagnosisInput = ({ form: { control, setValue, reset, getValues, clearErrors } }) => {
  const onPicked = diagnosis => {
    clearErrors();

    if (_.isEmpty(diagnosis)) {
      const diagnosisWasPicked = getValues("id");
      if (diagnosisWasPicked) reset();
      return;
    }

    const { code } = diagnosis;

    setValue("id", code);
    setValue("name", toDiagnosisLabel(diagnosis));
  }
  
  return (
    <>
      <SelectWithFilter
        control={control}
        required
        name="name"
        label="Diagnóstico"
        list={diagnosisList}
        matcherOptions={{ keys: ['code', 'name'], threshold: matchSorter.rankings.CONTAINS }}
        minInputLength={3}
        renderInputProps={{
          multiline: true,
          rows: 4
        }}
        helperText={helperText}
        onPicked={onPicked}
        getOptionLabel={diagnosis => {
          if (!_.isObject(diagnosis)) return diagnosis;
          return toDiagnosisLabel(diagnosis);
        }}
      />
      <input type="hidden" name="id" />
    </>
  );
};

export default DiagnosisInput;