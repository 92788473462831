import { useAuth } from '../../auth/context';
import _ from "lodash";
import { useEffect, useRef } from 'react';
import { Link, Stack, Typography } from '@mui/material';

const Clinti = () => {
  const iframeRef = useRef(null);
  const { user } = useAuth();
  const userParams = _.pick(user, ['id', 'licenseNumber', 'name', 'surname', 'workPhone']);

  const baseUrl = "https://clinti.com";
  useEffect(() => {
    if (!iframeRef.current) return;
    
    const isAgent = _.includes(user.email, "@recetario.com.ar");
    const token = "jvamuqEG4kWdv55nKQYSaz28YW2L96Nv";
    //TODO remove recetario param
    const clintiParams = { token, isAgent, recetario: "As3jd01_2ofd" };
    const parameters = _.assign({}, clintiParams, userParams);

    const handleLoad = () => {
      console.log("about to send post message")
      iframeRef.current.contentWindow.postMessage(parameters, baseUrl);
    };
  
    const iframeElement = iframeRef.current;
    iframeElement.addEventListener('load', handleLoad);
  
    return () => {
      iframeElement.removeEventListener('load', handleLoad);
    };
  }, []);

  return (
    <iframe ref={iframeRef} src={baseUrl} width='100%' height='100%'/>
  );
};

const BriefLink = () => {
  return <Link href="https://recetario.com.ar/vsr-briefing" target="_blank">acá</Link>;
};

const VideoLink = () => {
  return <Link href="https://www.youtube.com/watch?v=iLLgTkDLet8&t=1s" target="_blank">video instructivo</Link>;
};

const HelpSection = () => {
  return (
    <Typography textAlign="center" paddingTop="2rem">
      Desde esta sección vas a poder gestionar los estudios clínicos.
      Podés encontrar más información <BriefLink /> y en el siguiente <VideoLink />.
    </Typography>
  );
};

const Trials = () => {
  return (
    <Stack width="100%" height="100%" spacing={2}>
      <HelpSection />
      <Clinti />
    </Stack>
  );
};

export default Trials;
