import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const NavigationBar = ({
  previousStep,
  totalSteps,
  step,
  isLoading,
  nextDisabled,
}) => (
  <Stack direction="row" display="flex" justifyContent="center" spacing="2rem" pt="2rem">
    { step > 1 &&
      <Button onClick={previousStep} variant="contained">Anterior</Button>
    }
    { step < totalSteps ?
      <Button type="submit" variant="contained" disabled={nextDisabled}>Siguiente</Button>
      :
      <LoadingButton type="submit" variant="contained" loading={isLoading}>
        Finalizar
      </LoadingButton>
    }
  </Stack>
);

export default NavigationBar;