import { useState, useEffect, useContext, createContext } from "react";
import Api from "../apis/api";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { LoadingSpinner } from "../utils";
import { toReifiedUser } from "./User";
import { useNavigate, useQuickLinkToken } from "../utils/hooks";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [ user, setUser ] = useState();
  const [ isLoading, setIsLoading ] = useState(true);
  const [accessToken, setAccessToken] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const quickLinkToken = useQuickLinkToken();

  const isUnprotectedRoute = location.pathname === "/login" || location.pathname === "/register";

  useEffect(() => {
    if (!_.isEmpty(user)) {
      const { pathname } = location;
      const redirectionPath = !isUnprotectedRoute ? pathname : user.landingRoute();
      return navigate(redirectionPath, { replace: true });
    }

    const token = quickLinkToken || localStorage.getItem("accessToken");

    if (token)
      setLoggedInUser(token);
    else
      signout();
  }, [user]);
  
  const signin = (email, password) => new Api().login(email, password)
    .tap(({ accessToken }) => localStorage.setItem('accessToken', accessToken))
    .tap(({ accessToken }) => setLoggedInUser(accessToken));

  const signout = () => {
    localStorage.removeItem('accessToken');
    setUser(null);
    setIsLoading(false);
    navigate(isUnprotectedRoute ? location.pathname : "/login", { replace: true });
  };

  const signup = userToCreate => new Api().createUser(userToCreate);

  const setLoggedInUser = token => new Api(token).me()
    .then(toReifiedUser)
    .tap(setUser)
    .tap(() => setAccessToken(token))
    .tap(() => setIsLoading(false))
    .tapCatch(signout);

  const value = { signin, signout, user, signup, accessToken };

  return (
    <AuthContext.Provider value={value}>
      <LoadingSpinner isLoading={isLoading} absolute />
      {!isLoading && children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);