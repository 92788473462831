import _ from "lodash";
import { useState } from 'react';
import { PersonAddAlt1 as PatientIcon, Add as AddIcon, Share as ShareIcon } from '@mui/icons-material';
import { Fab, SpeedDial, SpeedDialAction } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ShareRegistrationDialog from './ShareRegistrationDialog';

const NewPatientButton = ({ direction, ...props }) => {
  const naviagte = useNavigate();
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(open => !open);

  const addNow = () => {
    naviagte("/patients/new");
  };

  const actions = [
    { icon: <AddIcon />, name: 'Nuevo paciente', action: addNow },
    { icon: <ShareIcon />, name: 'Link para pacientes', action: toggleOpen },
  ];

  return (
    <div>
      <Fab {...props}>
        <SpeedDial
          ariaLabel="add-patient"
          icon={<PatientIcon />}
          direction={direction}
          sx={{ position: 'absolute', top: 0, right: 0 }}
          {...props}
        >
          {_.map(actions, ({ icon, name, action }) =>
            <SpeedDialAction key={name} icon={icon} tooltipTitle={name} onClick={action} />
          )}
        </SpeedDial>
      </Fab>
      <ShareRegistrationDialog open={open} toggleOpen={toggleOpen} />
    </div>
  );
};

export default NewPatientButton;