import { Link } from "react-router-dom";
import { Card, CardMedia, CardContent, Typography, CardActionArea } from "@mui/material";

const NewOrderButton = () => (
  <Card sx={{ maxWidth: 345 }}>
    <CardActionArea component={Link} to="orders/new">
      <CardMedia
        sx={{ height: 140 }}
        image="https://res.cloudinary.com/dw86qy8xu/image/upload/v1694886753/orders.jpg"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Órdenes
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Usá esta opción para generar una nueva orden, licencia o certificado
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
);


export default NewOrderButton;