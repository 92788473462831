import { Button, Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from "../../../../utils/form";
import moment from "moment";
import Exporter from "../exporter";
import _ from "lodash";
import { useApi } from "../../../../utils/hooks";

const MedicalDocumentRepeater = ({ medicalDocument: { id, type }, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [repeatedDocument, setRepeatedDocument] = useState();
  const form = useForm({ defaultValues: { date: '' } });
  const { handleSubmit } = form;
  const typeName = type == "prescription" ? "receta" : "orden";
  const api = useApi();

  const onRepeat = repeatOptions => {
    setLoading(true);
    api.repeat(`${type}s`, id, repeatOptions)
    .tap(setRepeatedDocument)
    .tap(() => setLoading(false));
  };
  
  return <>
    <Dialog open={true}>
      <form onSubmit={handleSubmit(onRepeat)} autoComplete="off">
        <DialogTitle>Repetir {typeName} {id}</DialogTitle>
        <DialogContent>
          <Stack py="1rem" px="2rem">
            <DatePicker form={form} name="date" label="Nueva fecha" required minDate={moment()} />
          </Stack>
          <Stack p="1rem" spacing="1rem">
            <LoadingButton variant="contained" type="submit" loading={loading}>Generar {typeName}</LoadingButton>
            <Button variant="contained" onClick={onClose}>Finalizar</Button>
          </Stack>
        </DialogContent>
        </form>
    </Dialog>
    {!_.isEmpty(repeatedDocument) && <Exporter medicalDocument={repeatedDocument} onClose={onClose} />}
  </>;
};

export default MedicalDocumentRepeater;