import _ from "lodash";

class User {
  constructor(userJson) {
    _.assign(this, userJson)
  }
  
  hasPermissionFor(action) {
    return _.includes(this.medicalDocumentPermissions, action);
  }

  hasScope(scope) {
    return _.includes(this.scopes, scope);
  }
}

class DoctorUser extends User {
  medicalDocumentPermissions = ["repeat", "export"];

  landingRoute() {
    return "/";
  }
}

class AssistantUser extends User {
  medicalDocumentPermissions = ["export"];

  landingRoute() {
    return "/patients";
  }
}

export const toReifiedUser = userJson => {
  const UserTypeMap = {
    doctor: DoctorUser,
    assistant: AssistantUser
  };

  const UserType = _.get(UserTypeMap, userJson.type)
  if (!UserType) throw new Error("invalid user type");

  return new UserType(userJson);
};