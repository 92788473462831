import { TextField } from "./";

const ManualInput = ({ form: { control }, ...props }) => (
  <TextField
    control={control}
    fullWidth
    variant="outlined"
    required
    multiline
    rows={4}
    autoComplete="off"
    {...props}
  />
);

export default ManualInput;