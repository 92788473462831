import { Grid } from "@mui/material";
import NameInput from "./NameInput";
import DocumentNumberInput from "./DocumentNumberInput";
import HealthInsuranceSelect from "./HealthInsuranceSelect";
import InsuranceNumberInput from "./InsuranceNumberInput";
import EmailInput from "./EmailInput";
import PhoneInput from "./PhoneInput";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { GenderField } from "../../../../utils/form";
import GenderInput from "./GenderInput";
import BirthDateInput from "./BirthDateInput";

const defaultValues = {
  id: '',
  healthInsurance: '',
  insuranceNumber: '',
  name: '',
  surname: '',
  documentNumber: '',
  email: '',
  phone: '',
  gender: '',
  birthDate: ''
};

const PatientForm = ({ SurnameInput, children, onSubmit, contactRequired }) => {
  const form = useForm({ defaultValues });
  const { handleSubmit } = form;

  const onSubmitWithDefaults = patient => {
    const defaultedPatient = _.mapValues(patient, value => value || null);
    onSubmit(defaultedPatient);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitWithDefaults)} autoComplete="off">
      <Grid container spacing="1rem" pt="1rem">
        <Grid item xs={12} md={6}>
          <SurnameInput form={form} />
        </Grid>
        <Grid item xs={12} md={6}>
          <NameInput form={form} />
        </Grid>
      </Grid>
      <Grid container spacing="1rem" pt="2rem">
        <Grid item xs={12} lg={4}>
          <DocumentNumberInput form={form} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <GenderInput form={form} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <BirthDateInput form={form} />
        </Grid>
      </Grid>
      <Grid container spacing="1rem" pt="2rem">
        <Grid item xs={12} md={6}>
          <HealthInsuranceSelect form={form} />
        </Grid>
        <Grid item xs={12} md={6}>
          <InsuranceNumberInput form={form} />
        </Grid>
      </Grid>
      <Grid container spacing="1rem" pt="2rem">
        <Grid item xs={12} md={6}>
          <EmailInput form={form} required={contactRequired} />
        </Grid>
        <Grid item xs={12} md={6}>
          <PhoneInput form={form} required={contactRequired} />
        </Grid>
      </Grid>
      {children}
    </form>
  );
};

export default PatientForm;