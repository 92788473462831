import { Grid, Stack } from "@mui/material";
import _ from "lodash";
import TermsAndConditionsInput from "../TermsAndConditionsInput";
import { Name, Surname, DocumentNumber, LicenseNumber, LicenseType, Title, Address, Province, Signature, Passwords } from "../../utils/user/form";
import Email from "./Email";
import Phone from "./Phone";
import Specialty from "./Specialty";
import LicenseFile from "./LicenseFile";
import DoctorWithLicenseFile from "./DoctorWithLicenseFile";

const Fields = ({ form }) => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={6}>
        <Stack spacing="2rem">
          <Email form={form} />
          <Stack direction="row" spacing="2rem" justifyContent="center" width="100%">
            <Passwords form={form} />
          </Stack>
          <Stack direction="row" spacing="2rem" justifyContent="center" width="100%">
            <Name form={form} />
            <Surname form={form} />
          </Stack>
          <DocumentNumber form={form} />
          <Stack direction="row" spacing="2rem" justifyContent="center" width="100%">
            <LicenseType form={form} />
            <LicenseNumber form={form} />
          </Stack>
          <Stack direction="row" spacing="2rem" justifyContent="center" width="100%">
            <Title form={form} />
            <Specialty form={form} />
          </Stack>
          <Phone form={form} />
          <Stack direction="row" spacing="2rem" justifyContent="center" width="100%">
            <Address form={form} />
            <Province form={form} />
          </Stack>
          <Signature form={form} />
          <LicenseFile form={form} />
          <DoctorWithLicenseFile form={form} />
          <TermsAndConditionsInput form={form} />
        </Stack>
      </Grid>
    </Grid>
  )
};

export default Fields;