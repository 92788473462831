import _ from "lodash";
import TemplateDialog from "./TemplateDialog";
import { useApi } from "../../../../utils/hooks";

const TemplateUpdater = ({ templates, ...props }) => {
  const title = "Editar plantilla";
  const buttonText = "Actualizar";
  const api = useApi();
  
  const updateTemplate = ({ originalTemplate, templateDto }) => api.updateTemplate(originalTemplate.id, templateDto);

  const updateTemplates = template => {
    const index = _.findIndex(templates, { id: template.id });
    templates[index] = template;
  };

  return (
    <TemplateDialog
      onSubmitOperation={updateTemplate}
      afterSubmit={updateTemplates}
      templates={templates}
      title={title}
      buttonText={buttonText}
      {...props}
    />
  );
};

export default TemplateUpdater;