import { Stack, Typography } from "@mui/material";
import { ManualInput } from "../../../../../../utils/form";

const PosologyInput = props => {
  return (
    <ManualInput
      label="Posología"
      {...props}
      required={false}
      name={`${props.name}.posology`}
    />
  );
};

const SelectedMedicine = props => {
  const { form: { getValues }, name } = props;
  const selectedMedicineText = getValues(`${name}.text`);
  return (
    <Stack spacing={1}>
      <Typography>{selectedMedicineText}</Typography>
      <PosologyInput {...props} />
    </Stack>
  );
};

export default SelectedMedicine;