import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack } from "@mui/material";
import { Checkbox } from "../../../../utils/form";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

const SetContentPicker = ({ selectedTemplate, form, fieldName, defaultContent = "" }) => {
  const { content } = selectedTemplate;
  const options = _.split(content, ",");
  const optionsForm = useForm({ defaultValues: { options: _.map(options, () => false) } });
  const [open, setOpen] = useState(true);

  const toggleOpen = () => setOpen(open => !open);
  const onAccept = () => {
    const selectedOptions = _.filter(options, (option, index) => optionsForm.getValues("options")[index]);
    const fieldOptions = _(selectedOptions).map(option => `- ${option}`).join("\n");
    const fieldValue = `${defaultContent}${fieldOptions}`
    form.setValue(fieldName, fieldValue);
    toggleOpen();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Seleccione las opciones a utilizar</DialogTitle>
      <DialogContent>
        <Stack p="1rem" spacing="1rem">
          <form>
            <Grid container>
            {
              _.map(options, (option, index) => (
                <Grid item xs={12} sm={6} key={index} style={{ wordBreak: "break-word" }}>
                  <Checkbox form={optionsForm} name={`options[${index}]`} label={option} />
                </Grid>
              ))
            }
            </Grid>
          </form>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleOpen}>
          Cancelar
        </Button><Button onClick={onAccept}>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TextContentPicker = ({ selectedTemplate, form, fieldName }) => {
  useEffect(() => {
    const medicineValue = selectedTemplate.content;
    form.setValue(fieldName, medicineValue);
  }, [JSON.stringify(selectedTemplate)]);
};

const TemplateContentPicker = props => {
  const { selectedTemplate, fieldName, defaultContent, form } = props;

  useEffect(() => {
    if (!_.isEmpty(selectedTemplate)) return;

    form.setValue(fieldName, defaultContent);
  }, [JSON.stringify(selectedTemplate)]);

  if (_.isEmpty(selectedTemplate)) return null;
  const { inputMethod } = selectedTemplate;
  
  if (inputMethod === "text")
    return <TextContentPicker {...props} />;

  return <SetContentPicker {...props} />;
};

export default TemplateContentPicker;