import { IconButton, Stack } from "@mui/material";
import { Add } from "@mui/icons-material";
import _ from "lodash";
import { useState } from "react";
import TemplateCreator from "./creator";
import TemplatePicker from "./picker";

const AddTemplateButton = props => (
  <IconButton {...props} style={{ padding: "1rem", marginLeft: "0.5rem" }}>
    <Add />
  </IconButton>
);

const TemplatesMenu = props => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const toggleDialogOpen = () => setDialogOpen(open => !open);

  return (
    <>
      <Stack direction="row" spacing="1rem" style={{ marginTop: "1rem" }}>
        <TemplatePicker {...props} />
        <AddTemplateButton onClick={toggleDialogOpen} />
      </Stack>
      <TemplateCreator {...props} open={dialogOpen} toggleOpen={toggleDialogOpen} />
    </>
  );
};

export default TemplatesMenu;