import * as yup from "yup";
import { email as emailSchema, text as textSchema, signature as signatureSchema } from "../../utils/form/schemas";
import _ from "lodash";

const nonInstitutionalTextSchema = yup.string().when('$user', {
  is: ({ profile }) => _.isEmpty(profile.healthCenter),
  then: textSchema,
  otherwise: yup.string().nullable(true).notRequired()
});

const profileValidations = {
  email: emailSchema,
  legend: textSchema,
  phone: nonInstitutionalTextSchema,
  address: nonInstitutionalTextSchema,
  signature: signatureSchema
};

export default profileValidations;