import { Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import NavigationBar from "../../../../../utils/wizard/navigation";
import DiagnosisInput from "./DiagnosisInput";

const defaultValues = {
  id: '',
  name: '',
};

const DiagnosisForm = ({ onFinish, ...props }) => {
  const form = useForm({ defaultValues });
  const { handleSubmit } = form;
  
  const onSubmit = diagnosis => {
    const{ name } = diagnosis;
    const diagnosisToSubmit = name;
    onFinish({ diagnosis: diagnosisToSubmit });
    props.nextStep();
  };
  
  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Stack spacing={2}>
        <Typography variant="h5">Diagnóstico</Typography>
        <DiagnosisInput form={form} />        
      </Stack>
      <NavigationBar step={3} {...props} />
    </form>
  );
};

export default DiagnosisForm;