import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import Login from "../modules/login";
import Register from "../modules/register";
import Home from "../modules/home";
import NewPrescription from "../modules/prescriptions/new";
import NewOrder from "../modules/orders/new";
import TermsAndConditions from "../modules/termsAndConditions";
import { AuthProvider, useAuth } from "../auth/context";
import RequestPasswordRecovery from "../modules/passwordRecovery";
import ChangePassword from "../modules/passwordRecovery/change";
import NavigationBar from "./NavigationBar";
import { Stack } from "@mui/material";
import Patients from "../modules/patients";
import NewPatient from "../modules/patients/new";
import Settings from "../modules/settings";
import Trials from "../modules/trials";
import PatientSelfRegister from "../modules/patientSelfRegister";
import { useAutoSignout } from "../utils/hooks";

const WithAuthProvider = () => (
  <AuthProvider>
    <Outlet />
  </AuthProvider>
);

const ProtectedRoute = () => {
  useAutoSignout();
  
  return (
    <Stack alignItems="center" width="100%" height="100%">
      <NavigationBar />
      <Outlet />
    </Stack>
  )
};

const DoctorRoute = ({ children, scope }) => {
  const { user } = useAuth();
  
  if (user.type != "doctor" || (scope && !user.hasScope(scope)))
    return <Navigate to={user.landingRoute()} />;

  return children;
};

export default createBrowserRouter([
  {
    element: <WithAuthProvider />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        element: <ProtectedRoute />,
        children: [
          {
            path: "/",
            element: <DoctorRoute>
              <Home />
            </DoctorRoute>,
          },
          {
            path: "/settings",
            element: <DoctorRoute>
              <Settings />
            </DoctorRoute>,
          },
          {
            path: "prescriptions",
            children: [
              {
                path: "new",
                element: <DoctorRoute>
                  <NewPrescription />
                </DoctorRoute>,
              }
            ]
          },
          {
            path: "orders",
            children: [
              {
                path: "new",
                element: <DoctorRoute>
                  <NewOrder />
                </DoctorRoute>,
              }
            ]
          },
          {
            path: "trials",
            children: [
              {
                path: "",
                element: <DoctorRoute scope="trials">
                  <Trials />
                </DoctorRoute>,
              }
            ]
          },
          {
            path: "patients",
            children: [
              {
                path: "",
                element: <Patients />,
              },
              {
                path: "new",
                element: <NewPatient />,
              }
            ]
          },
        ]
      },
    ]
  },
  {
    path: "termsAndConditions",
    element: <TermsAndConditions />,
  },
  {
    path: "passwordRecovery",
    children: [
      {
        path: "request",
        element: <RequestPasswordRecovery />,
      },
      {
        path: "change",
        element: <ChangePassword />,
      }
    ]
  },
  {
    path: "patients/selfRegister",
    element: <PatientSelfRegister />,
  },
]);