const insurances = [
  "APM",
  "APOS",
  "APRESS",
  "CAJA NOTARIAL C. DE S.S. DEL COL. ESCR.",
  "FAMYL SA SALUD",
  "GALENO",
  "GRUPO SAN NICOLÁS",
  "IOMA AMBULATORIO",
  "LUIS PASTEUR",
  "MEDICUS",
  "MEDIFE",
  "MET MEDICINA PRIVADA",
  "OBSBA",
  "OMINT",
  "OPDEA",
  "OSDIPP",
  "OSMECON PLAN MEDICO MATANZA",
  "OSMECON E. ECHEVERRÍA",
  "OSMECON PLAN FAMILIA LOMAS DE ZAMORA",
  "OSMECON SAMI MATANZA",
  "OSMECON TRENQUE LAUQUEN",
  "PROGRAMAS MÉDICOS",
  "SCIS MEDICINA PRIVADA",
  "SWISS MEDICAL",
  "OSPUNCPBA - Unicen Tandil",
  "OSPF",
  "INSTITUTO DE SEGUROS DE JUJUY",
  "OSDE",
  "OSMECON EMPRESAS ESTEBAN ECHEVERRIA",
  "OSMECON PLAN MEDICO LOMAS DE ZAMORA",
  "ASOCIACION MEDICA DE PERGAMINO (OSAM)",
  "OBRA SOCIAL E. W. HOPE",
  "MUTUAL FEDERADA",
  "OSSACRA - GENERAL",
  "PREVENCION SALUD",
  "OSSEG",
  "OSPF",
  "WITCEL",
  "Acasalud",
  "ACCORDSALUD",
  "ACINDAR  MUTAL SALUD",
  "ACTIM",
  "ACTIVASALUD",
  "ADEF ASOCIACIÓN TRABAJADORES DE FARMACIA",
  "ADOS (asociación de obras sociales)",
  "ALIANZA MEDICA",
  "ALLMEDICINE",
  "ALTA SALUD",
  "AMC SALUD / AMC SOLIDARIA",
  "AMEBPABA",
  "AMEBPBA - Bco Provincia",
  "AMFFA (ASOC MUTUAL DE FARM. F. AMEGHINO)",
  "AMICOS",
  "AMSAFE",
  "AMS CAMIONEROS (A.M.S.C.) (ASOCIACION MU",
  "AMUR",
  "AOMA OBRA SOCIAL DE LA ACTIVIDAD MINERA",
  "APDIS",
  "APRES COBERTURA MÉDICA",
  "APROSS",
  "APSOT",
  "APTM",
  "ART SANATORIO",
  "ASE",
  "ASIMRA",
  "ASIPRETECCION MÉDICA ( OSEPJANA)",
  "ASISTIRMEDICINA",
  "ASMEPRIV",
  "ASOCIACION ECLESIASTICA SAN PEDRO",
  "ASOCIACIÓN MÉDICA DE ROSARIO (AMR)",
  "ASOCIART",
  "ATSA",
  "AUSTRAL PLAN DE SALUD",
  "AVALIAN (ACASALUD)",
  "AZUL SALUD",
  "BERKLEY ART",
  "BIENESTAR SALUD",
  "BOREAL",
  "BRAMED",
  "BRISTOL MEDICINE",
  "CAJA CIENCIAS ECONÓMICAS",
  "CAJA DE INGENIERÍA (LA CAJA DE PREV. SOC",
  "CAJA DE SERVICIOS SOCIALES (CSS)",
  "CAJA FORENSE",
  "CAJA SOCIAL COL ESCRIBANOS BUENOS AIRES",
  "CAMARA DEL TABACO DE MISIONES",
  "CAMINOS PROTEGIDOS ART",
  "CAMIONEROS DE RÍO NEGRO",
  "CAMIONEROS DE SANTIAGO DEL ESTERO",
  "CAMIONEROS PRIMERO (CAM. PRIMERO) SALUD",
  "CAMI SALUD",
  "CAPTA",
  "CASA",
  "Celiaquía",
  "CEMIC",
  "CENTRO ASISTENCIAL MEDICINA PRIVADA (CA)",
  "CENTRO MÉDICO PUEYRREDÓN",
  "Cesación Tabáquica",
  "CIMA SA",
  "CIRCUITO MÉDICO DE SALUD",
  "COBERMED",
  "COLMED",
  "COMEI",
  "CONSEJO DE PROFESIONALES CPCE",
  "CONSTRUIR SALUD",
  "COOMARPES",
  "CRUZ MED CRUZ MED SALUD S.A",
  "CTCP",
  "CYNTHIOS SALUD",
  "DAMSU",
  "DAS CONGRESO DE LA NACIÓN",
  "DASPU",
  "DASUTEN",
  "DASU - U.N. DE LA PATAGONIA SAN JUAN BOS",
  "DELTA SALUD",
  "DIBA ARMADA ARGENTINA",
  "DOCTORED",
  "DOSEM",
  "DOSEP",
  "DOSUBA",
  "ELEVAR (CONFITEROS)",
  "ELEVAR (PASTELEROS)",
  "EMEDIC",
  "EMERGENCIAS",
  "ENSALUD",
  "EXPERTA ART",
  "FEDECAMARAS",
  "FEDECAMARAS (OSF)",
  "FEDERACION PATRONAL ART",
  "FEDERADA SALUD",
  "FEMÉDICA",
  "FEMESA",
  "FRUTOS",
  "FUNDACION COMEI",
  "GALENO ART.",
  "GAP (GRUPO ASISTENCIA PSICOLOGIA SA)",
  "GEA",
  "Generar Salud (Obra Social de Luz y Fuer",
  "GENESEN",
  "GRUPO CORPORACION MÉDICA",
  "GRUPO GROISA",
  "Hábitos Saludables",
  "HEALT MEDCIAL SA",
  "HELIOS SALUD SA",
  "HOMINIS",
  "HOMMIS SA",
  "HOSPITAL ALEMAN",
  "HOSPITAL BRITANICO",
  "HOSPITAL ESPAÑOL DE MENDOZA",
  "HOSPITAL ITALIANO",
  "IAMIP",
  "IAPOS",
  "IASEP",
  "IMA",
  "IMA SALUD",
  "INGENIERÍA SALUD",
  "INSSSEP",
  "INTEGRAL SALUD",
  "IOMA",
  "IOSCOR",
  "IOSEP",
  "IOSFA INST. DE O.S. DE LAS FUERZAS ARMAD",
  "IOSPER",
  "IPAM SALUD MEDICINA PREPAGA",
  "IPROSS",
  "I.P.S INSTITUTO PROV. DE SALUD DE SALTA",
  "IPS MISIONES",
  "IPSST  (SEGURIDAD SOCIAL)",
  "IRAB",
  "ISSN - INST. DE SEG. SOCIAL DEL NEUQUÉN",
  "ISSUNNE INSTITUTO DE SERVICIOS SOCIALES",
  "JERARQUICO",
  "LA HOLANDO ART",
  "LA PEQUEÑA FAMILIA",
  "LA SEGUNDA ART",
  "LIDERAR ART",
  "MAPAR SRL - MEDICINA ASISTENCIAL POR ABO",
  "MAPFRED ART",
  "MEDICAL",
  "MEDICALS",
  "MEDICAR WORK",
  "MEDICIEN",
  "MEDICINA ESENCIAL",
  "MEDINALS",
  "MEDITAR",
  "MEDYCIN",
  "MEPLIFE SALUD SRL",
  "MITA",
  "MOSAISTA",
  "MPN - MEDICAMENTOS DE PRIMER NIVEL",
  "MUTUAL DE JUGADORES DE FÚTBOL",
  "MUTUAL DE TAXI",
  "MUTUAL LUZ Y FUERZA (MUTUALYF)",
  "MUTUAL MÉDICA RÍO CUARTO",
  "NAQUA",
  "NOBIS",
  "OBRA SOCIAL ACEROS PARANÁ",
  "OBRA SOCIAL ASOCIACIÓN LUGONES",
  "OBRA SOCIAL DE GRINCHEROS Y MAQUINISTAS",
  "OBRA SOCIAL OAM",
  "OBRA SOCIAL PROVINCIA",
  "OBRA SOCIAL SERVICIO PENITENCIARIO",
  "OBRA SOCIAL SUBSIDIO DE SALUD",
  "OBRA SOCIAL SUMA",
  "OBRA SOCIAL UTA",
  "OMINT ART",
  "OSA",
  "OSAC",
  "OSADEF",
  "OSADRA",
  "OSALARA",
  "OSAM",
  "OSANA SALUD",
  "OSAPM",
  "OSAPYEA",
  "OSARPYH",
  "OSBA",
  "OS BANCARIA ARGENTINA",
  "OSCAM (CAMIONEROS)",
  "OSCAMGLYP",
  "OSCCPTAC",
  "OSCE",
  "OSCEP",
  "OSCHOCA",
  "OSCN",
  "OSCOMM",
  "OSCONARA",
  "O.S. CONDUCTORES DE CAMIONES-CAMIONEROS",
  "OSCRAIA",
  "OSCTCP (UTA)",
  "OSDEL",
  "OSDEM",
  "OSDEPYM",
  "OSDIC",
  "OSDO",
  "OSDOP",
  "OSEAM",
  "OSECAC",
  "OSEDA",
  "OSEDEIV",
  "OSEIV",
  "OSEMM",
  "OSETRA",
  "OSETYA",
  "OSFATLYF SALUD",
  "OSFATUN",
  "OSFE",
  "OSFGPICD",
  "OSFOT",
  "OSGMGM",
  "OSIAD",
  "OSIM",
  "OSIPA",
  "OSITAC",
  "O.S. JARDINEROS",
  "OSJERA",
  "OSJOMN",
  "OSJPVYF",
  "OSLYF",
  "OSMA",
  "OSMATA",
  "OSMEDICA",
  "OSMISS",
  "OSMITA",
  "OSMTT",
  "OSOCN",
  "OSOCNA (O.S. DE COMISARIOS NAVALES)",
  "OSOEFRNN",
  "OSOEFRNYN",
  "OSOETSYLARA",
  "OSOSS",
  "OSPA",
  "OSPAC",
  "OSPACA",
  "OSPACARP",
  "OSPACLA",
  "OSPACP",
  "OSPADEP",
  "OSPAGA",
  "OSPAP",
  "OSPAPEL",
  "OSPAR",
  "OSPAT",
  "OSPATCA",
  "OSPAV",
  "OSPAVIAL",
  "OSPBLCA",
  "OSPBRYL O.S. DEL PERSONAL DE RECOLECCIÓN",
  "OSPCN",
  "OSPCRP",
  "OSPCYD",
  "OSPE",
  "OSPEA",
  "OSPEC",
  "OSPECA",
  "OSPECON",
  "OSPECOR",
  "OSPEDYC",
  "OSPEFYEPCA",
  "OSPEGAP",
  "OSPEJ",
  "OSPELMM",
  "OSPEP",
  "OSPEPA",
  "OSPEPBA",
  "OSPEPRI O. SOC. DE PETROLEROS PRIV. DE N",
  "OSPEPU",
  "OSPERCIN",
  "OSPERYH",
  "OSPERYHRA",
  "OSPESA",
  "OSPESGA",
  "OSPESGYPE",
  "OSPET",
  "OSPETAX",
  "OSPF OBRA SOC. DEL PERSONAL DE FARMACIA",
  "OSPG",
  "OSPIA",
  "OSPIC",
  "OSPICA",
  "OSPICAL",
  "OSPICHA",
  "OSPIDA",
  "OSPIF",
  "OSPIHMP",
  "OSPIL",
  "OSPILM",
  "OSPIM",
  "OSPIMO",
  "OSPIP",
  "OSPIQYP",
  "OSPIS",
  "OSPIT",
  "OSPIV",
  "OSPIVE",
  "OSPJN - OS. DEL PODER JUDICIAL DE LA NAC",
  "OSPJTAP",
  "OSPLA",
  "OSPLAD",
  "OSPL (LADRILLEROS)",
  "OSPM",
  "OSPMA",
  "OSPMJCHPSI",
  "OSP ( OBRA SOCIAL PROVINCIA DE SAN JUAN)",
  "OSPOCE",
  "OSPP",
  "OSPPCYQ",
  "OSPPEA",
  "OSPPRA",
  "OSPRERA",
  "OSPRO - PERSONAL DE PRENSA DE ROSARIO",
  "OSPSA",
  "OSPSIP",
  "OSPTA",
  "OSPTV",
  "OSPUAYE",
  "OSRJA",
  "OSSACRA (AMA SALUD)",
  "OSSB",
  "OSPAÑA",
  "OSSHELL",
  "OSSIMRA",
  "OSSOELSAC",
  "OSTECF",
  "OSTEE",
  "OSTEL",
  "OSTEP",
  "OSTIG",
  "OSTPBA",
  "OSTPCHPYARA",
  "OSTRAC",
  "OSTV",
  "OSTVLA",
  "OSUCI",
  "OSUNLAR",
  "OSUOMRA",
  "OSUTHGRA",
  "OSV",
  "OSVARA",
  "OSVVRA",
  "OSYC",
  "OSYPF",
  "PAMI",
  "PARAMEDIC",
  "PARQUE SALUD SA",
  "PARQUISTAS Y VIVERISTAS",
  "PATRONES DE CABOTAJE",
  "PBS (CARDIOLÓGICO DE CTES)",
  "PERKINS",
  "PFA SUPERINTENDENCIA DE BINIESTAR",
  "PIEVE SALUD",
  "PLAN SALUD BRITANICO",
  "Plan Sumar",
  "PLENIT",
  "PMI",
  "PODER JUDICIAL",
  "POLICIA FEDERAL",
  "PREME",
  "PRIMEDIC SALUD",
  "PRENSA - OBRA SOCIAL",
  "PREVENCION ART",
  "PRODIABA",
  "ProEpi",
  "PROGEMA",
  "Programa de Prevencion del Deterioro Cog",
  "Programa de Salud Mental",
  "PROGRAMA NACIONAL SALUD (EX PROFE)",
  "PROVINCIA ART",
  "QBE",
  "QUALITAS",
  "RECONQUISTA ART",
  "RED DE SALUD DE SEGURO MÉDICO SRL",
  "Remediar",
  "SABER CENTRO MÉDICO",
  "SABER SALUD",
  "SADAIC",
  "Salud Bucal",
  "SALUD DEL NUEVO ROSARIO",
  "SALUD ROSARIO (BRITANICA)",
  "SALUD SEGURA MAX",
  "Salud Sexual y Reproductiva",
  "SAMI",
  "SANARTE ASISTENCIA MÉDICA PRIVADA",
  "SANATORIO JUNIN",
  "SANCOR SALUD",
  "SAT (OBRA SOCIAL PERSONAL TELEVISION)",
  "SCIENTIS",
  "SEMPRE ( LA PAMPA)",
  "SEROS (INST. DE SEG. SOCIAL Y SEGUROS)",
  "SERVESALUD",
  "SERVICIO SOCIAL MUNICIPAL",
  "SERVIN LIFE",
  "SERVIRED",
  "SIMEPA",
  "SIPSSA",
  "SIS SISTEMA INTEGRAL DE SALUD CHIVILCOY",
  "SMAUNAM",
  "SMAUNSE",
  "SMG ART",
  "SMG LIFE",
  "SM MUTUAL (SM DEPARTAMENTO LAS COLONIAS)",
  "SOSUNC (OBRA SOCIAL UNIVERSITARIA)",
  "SPF (SEVICIO PENITENCIARIO FEDERAL)",
  "SPS SALUD",
  "SR SALUD",
  "STAFF MÉDICO",
  "THEMA RED MÉDICA",
  "TINY",
  "TV SALUD",
  "UAI SB SALUD",
  "UIA SALUD",
  "UNIDAD CORONARIA",
  "UNIMED",
  "UNION PERSONAL",
  "UNL O.S. UNIVERSIDAD NAC. DEL LITORAL",
  "UNR",
  "UNSA",
  "UNS (UNIVERSIDAD NACIONAL SUR)",
  "U.O.M",
  "UOMA - UNIÓN OBRERA MOLINERA ARGENTINA",
  "UPFPARA",
  "UPSJ - UNIÓN PROGR. DE SEC. JUDICIALES",
  "U.T.H.G.R.A.",
  "VIH, ITS y Hepatitis Virales",
  "VISITAR/ANDAR",
  "VITAL",
  "COLONIA SUIZA ART",
  "OSEP Catamarca",
  "OSEP Mendoza",
  "Andina ART",
  "OSAMOC - Obra Social de los Obreros Católicos",
  "AGRUPACION SANATORIAL SUR S.A.",
  "OSTRAMEN - O. S. JOCKEY CLUB E HIPODROMOS",
  "SMAI - Servicio Médico Asistencial Integral",
  "LEAL MEDICA",
  "PREMEDIC",
  "OSPETELCO - O.S. PERSONAL DE TELECOMUNICACIONES",
  "UNO SALUD",
  "BAYRES PLAN",
  "OSRM OBRA SOCIAL DEL PERSONAL DE REFINERIAS DE MAIZ",
  "OBRA SOCIAL FORD ARGENTINA S.A.",
  "OSFYB OBRA SOCIAL DE FARMACEUTICOS Y BIOQUIMICOS",
  "O.S. DE TRABAJADORES DEL PETROLEO/GAS PRIVADO DE CHUBUT ",
  "OSME - O.S.P/PERSON.MINISTERIO DE ECONOMÍA ",
  "OSPS MERCEDES BENZ ARG",
  "VITAS S.A.",
  "All Salud",
  "Amsterdam Salud",
  "Andes salud",
  "Arquitectura e Ingeniería Salud",
  "ASI Salud",
  "ASPURC",
  "ASUNT",
  "Binimed",
  "BUPA",
  "CA RAFAELA",
  "Caja Notarial Córdoba",
  "Capitanes de Pesca",
  "Celius Salud",
  "ConSalud",
  "CORPORACION MÉDICA ASISTENCIAL",
  "CRISOLMED",
  "DASMI",
  "Economicas Salud",
  "ESPORA SALUD",
  "Ética Salud",
  "GENERAR SALUD",
  "Gerdanna Salud",
  "INSTITUTO MUNICIPAL RIO CUARTO",
  "Integral Amci",
  "Mapaci Salud",
  "MEDICARDIO",
  "Medicenter Salud",
  "MEDIMAS",
  "MEDIN",
  "MGN Salud",
  "MPM SALUD",
  "Nivel Salud",
  "OSAP",
  "OSCAP",
  "OSCEARA",
  "OSCO",
  "Osef",
  "OSFYHC",
  "OSPBB",
  "OSPECESA",
  "OSPELSYM",
  "OSPMLM",
  "OSPROTURA",
  "OSSSB",
  "Ossurbac",
  "OSUNSa",
  "OSVIMED S.A.",
  "Plan Médico del Sanatorio San José",
  "Previnca Salud",
  "PRIVAMED",
  "RAS  Red Argentina de Sanatorios",
  "Salud Profesional",
  "SANITAS LIFE",
  "Soremer",
  "SOS SALUD",
  "UDOCBA",
  "VÍA SANO",
  "VSGROUP",
  "Particular"
];

export default insurances;