import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import NavigationBar from "../../../../../utils/wizard/navigation";
import _ from "lodash";
import PatientForm from "../../../patient/form";
import SurnameInput from "./SurnameInput";
import { useApi } from "../../../../../utils/hooks";
import { useSearchParams } from "react-router-dom";

const SurnameInputWithLoading = props => {
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const api = useApi();
  const [searchParams] = useSearchParams();
  
  useEffect(() => {
    if (!_.isEmpty(patients)) return;

    const patientId = parseInt(searchParams.get("patientId"));

    api.patients()
    .tap(setPatients)
    .tap(patients => patientId && setPatient(_.find(patients, { id: patientId })))
    .tap(() => setIsLoading(false));
  }, []);

  return <SurnameInput patient={patient} patients={patients} loading={isLoading} {...props} />;
};

const MedicalDocumentPatientForm = ({ onFinish, ...props }) => {
  const onSubmit = patient => {
    onFinish({ patient });
    props.nextStep();
  };

  return (
    <>
      <Typography variant="h5" gutterBottom>Paciente</Typography>
      <PatientForm onSubmit={onSubmit} SurnameInput={SurnameInputWithLoading}>
        <NavigationBar step={1} {...props} />
      </PatientForm>
    </>
  );
};

export default MedicalDocumentPatientForm;