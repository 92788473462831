import { ManualInput } from "../../../../utils/form";

const MedicineManualInput = props => (
  <ManualInput
    name="medicine"
    label="Solicitud"
    {...props}
  />
);

export default MedicineManualInput;