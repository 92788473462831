import { FormControl } from "@mui/material";
import { Controller } from "react-hook-form";
import { SelectWithFilter } from "../";
import _ from "lodash";

const FormSelectWithFilter = props => { 
  const { control, name, required, pattern } = props;
  return <FormControl fullWidth>
    <Controller
      control={control}
      name={name}
      rules={{
        required,
        pattern
      }}
      render={({
        field: { value, onChange },
        fieldState: { error }
      }) => (
        <>
          <SelectWithFilter
            onChange={onChange}
            error={error}
            freeSolo  
            value={value}
            {...props}
          />
        </>
      )}
    />
  </FormControl>
};

export default FormSelectWithFilter;