import TextField from "./TextField";
import emailRegex from "../emailRegex";

const EmailInput = ({ form: { control }, required, ...props }) =>
  <TextField
    control={control}
    name="email"
    label="Email"
    variant="outlined"
    rules={{ required, pattern: { value: emailRegex } }}
    {...props}
  />;

export default EmailInput;