import { LoadingButton } from "@mui/lab";
import { Box, Stack } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { EmailInput } from "../../utils/form";
import { Alert, ErrorAlert } from "../../utils";
import { useNavigate } from "react-router-dom";
import Api from "../../apis/api";

const RequestPasswordRecovery = () => {
  const form = useForm({
    defaultValues: {
      email: '',
    }
  });
  const [ isLoading, setIsLoading ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ recoveryRequested, setRecoveryRequested ] = useState(false);
  const navigate = useNavigate();
  const api = new Api();

  const { handleSubmit } = form;

  const requestPasswordRecovery = ({ email }) => {
    setIsLoading(true);
    api.requestPasswordRecovery(email)
    .then(() => setRecoveryRequested(true))
    .catch(() => setError(true))
    .tap(() => setIsLoading(false));
  };

  const onAlertClose = () => {
    setRecoveryRequested(false);
    navigate("/login");
  };

  const onErrorAlertClose = () => {
    setError(false);
  };

  return (
    <Stack display="flex" flexDirection="column" alignItems="center" spacing="2rem" pt="2rem">
      <Box>
        Recuperá tu contraseña
      </Box>
      <form onSubmit={handleSubmit(requestPasswordRecovery)} autoComplete="off">
        <Stack display="flex" flexDirection="column" alignItems="center" pt="3rem" spacing="2rem">
          <EmailInput form={form} required />
          <LoadingButton type="submit" variant="contained" sx={{paddingX: "6rem"}} loading={isLoading} disabled={recoveryRequested}>
            Recuperar contraseña
          </LoadingButton>
        </Stack>
      </form>
      <Alert
        severity="success"
        message="Si existe una cuenta con el mail ingresado, enviaremos un correo con las instrucciones para recuperar tu contraseña. Te redirigimos al inicio de sesión..."
        onClose={onAlertClose}
        show={recoveryRequested}
        duration={7000}
      />
      <ErrorAlert
        show={error}
        onClose={onErrorAlertClose}
        message="Hubo un error al intentar recuperar la contraseña. Por favor, intentá de nuevo o contactanos a consultas@recetario.com.ar"
      />
    </Stack>
  );
};

export default RequestPasswordRecovery;