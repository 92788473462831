import { useEffect, useRef } from "react";
import { useAuth } from "../../auth/context";

const inactivityMinutes = 2 * 60; // 2 hours
const inactivityPeriod =  inactivityMinutes * 60 * 1000;

const useAutoSignout = () => {
  const logoutTimerIdRef = useRef(null);
  const { signout } = useAuth();

  useEffect(() => {
    const autoLogout = () => {
      if (document.visibilityState === 'hidden') {
        const timeOutId = window.setTimeout(signout, inactivityPeriod);
        logoutTimerIdRef.current = timeOutId;
      } else {
        window.clearTimeout(logoutTimerIdRef.current);
      }
    };
  
    document.addEventListener('visibilitychange', autoLogout);
  
    return () => {
      document.removeEventListener('visibilitychange', autoLogout);
    };
  }, []);
};

export default useAutoSignout;