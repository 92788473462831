import { Snackbar, Alert } from "@mui/material";
import _ from "lodash";

const CustomAlert = ({ show, message, duration = 6000, error, severity, keepOpen, ...props }) => {
  const onSnackbarClose = (event, reason) => {
    if (reason === 'clickaway' || keepOpen) {
      return;
    }

    const { onClose = _.identity } = props;
    onClose(event, reason);
  };

  return (
    <Snackbar
      autoHideDuration={duration}
      severity={severity}
      open={show || !_.isEmpty(error)}
      onClose={onSnackbarClose}
    >
      <Alert severity={severity} sx={{ width: '100%' }} {...props}>
        { message }
      </Alert>
    </Snackbar>
  );
};

export default CustomAlert;