import { SelectWithFilter } from "../../../../utils";
import _ from "lodash";
import { IconButton, Stack } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import ConfirmationDialog from "../../../../utils/ButtonWithConfirmation/ConfirmationDialog";
import { useState } from "react";
import TemplateUpdater from "./updater";
import { useApi } from "../../../../utils/hooks";

const IconButtonWithConfirmation = ({ icon, template, ...props }) => {
  if (!template.id) return null;
  return (
    <IconButton {...props}>
      {icon}
    </IconButton>
  );
};

const TemplatePicker = ({ templates, selectedTemplate, setSelectedTemplate, ...props }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const toggleDialogOpen = () => setDialogOpen(open => !open);
  const api = useApi();

  const [templateToDelete, setTemplateToDelete] = useState(null);
  const [templateToUpdate, setTemplateToUpdate] = useState(null);

  const { id } = templateToDelete || templateToUpdate || {};
  const onDelete = () =>
    api.deleteTemplate(id)
    .tap(() => _.remove(templates, { id }));

  const selectTemplateToUpdate = (template) => {
    setTemplateToUpdate(template);
    toggleDialogOpen();
  };

  const UpdateTemplate = ({ template }) => <IconButtonWithConfirmation icon={<Edit />} onClick={() => selectTemplateToUpdate(template)} template={template} />;
  const DeleteTemplate = ({ template }) => <IconButtonWithConfirmation icon={<Delete />} onClick={() => setTemplateToDelete(template)} template={template} />;

  return (
    <>
      <SelectWithFilter
        name="template"
        label="Plantilla"
        onChange={setSelectedTemplate}
        value={selectedTemplate}
        list={templates}
        getOptionLabel={template => template.name}
        renderOption={(props, template) => (
          <Stack direction="row" width="100%" justifyContent="space-between" key={props.key}>
            <li {...props} style={{ width: "100%" }}>
              {template.name}
            </li>
            <UpdateTemplate template={template} />
            <DeleteTemplate template={template} />
          </Stack>
        )}
        fullWidth
        {..._.omit(props, "defaultContent")}
      />
      {dialogOpen && <TemplateUpdater originalTemplate={templateToUpdate} templates={templates} open={dialogOpen} toggleOpen={toggleDialogOpen} setSelectedTemplate={setSelectedTemplate} {...props} />}
      <ConfirmationDialog
        isVisible={!_.isEmpty(templateToDelete)}
        setIsVisible={() => setTemplateToDelete(null)}
        message={!_.isEmpty(templateToDelete) ? `¿Está seguro de que desea eliminar la plantilla ${templateToDelete.name}?` : ""}
        onConfirm={onDelete}
      />
    </>
  );
};

export default TemplatePicker;