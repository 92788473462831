import { Button, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";
import _ from "lodash";

const FileInput = ({ control, name, label, required, helperText, ...props }) => (
  <Controller
    control={control}
    name={name}
    rules={{
      required
    }}
    render={({
      field: { value, onChange },
      fieldState: { error }
    }) => (
      <>
        <Button
          variant="contained"
          component="label"
          value={value}
          label={`${label}${required ? "*" : ""}`}
          color={!_.isEmpty(error) ? "error" : "primary"}
          sx={{ textTransform: "none" }}
          {...props}
        >
          {label}
          <input
            type="file"
            onChange={({ target: { files } }) => onChange(files[0])}
            accept=".png, .jpg, .jpeg"
            hidden
          />
        </Button>
        {helperText && <FormHelperText error={!_.isEmpty(error)} style={{ margin: "3px 14px 0 14px" }}>{helperText}</FormHelperText>}
        {!_.isEmpty(error) ? error.message : value.name}
      </>
    )
    }
  />
);

export default FileInput;