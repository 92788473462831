import { TextField } from "../../../../utils/form";

//TODO reuse pattern with exporter's
const PhoneInput = ({ form: { control }, required }) => (
  <TextField
    control={control}
    fullWidth
    name="phone"
    label="Número de teléfono"
    variant="outlined"
    rules={{ required, pattern: { value: /^(?=.*[0-9])[- +()0-9]+$/i } }}
  />
);

export default PhoneInput;