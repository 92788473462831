import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import { useAuth } from '../auth/context';
import logo from "./logo.png";
import _ from "lodash";
import { Link, useLocation } from 'react-router-dom';
import PageLink from './PageLink';
import { useTheme } from '@emotion/react';
import { ListItemIcon, ListItemText, Stack } from '@mui/material';
import { Settings as SettingsIcon, Logout as LogoutIcon } from '@mui/icons-material';
import { useIsInQuickLink, useNavigate } from '../utils/hooks';

const Logo = () => {
  const isInQuickLink = useIsInQuickLink();
  const Wrapper = isInQuickLink ? Box : Link;
  return (
    <Wrapper to="/">
      <img src={logo} style={{ width: "80%" }} />
    </Wrapper>
  )
};

const allPages = [{ name: 'Inicio', path: "/" }, { name: 'Pacientes', path: "/patients", inQuickLink: true }];
const usePages = () => {
  const isInQuickLink = useIsInQuickLink();

  if (!isInQuickLink) return allPages;
  return _.filter(allPages, "inQuickLink");
};

const useMenuOptions = () => {
  const { signout } = useAuth();
  const navigate = useNavigate();
  const isInQuickLink = useIsInQuickLink();

  const allOptions = 
  [
    {
      name: "Ajustes",
      icon: <SettingsIcon />,
      onClick: () => navigate("/settings"),
      inQuickLink: true
    },
    {
      name: "Cerrar sesión",
      icon: <LogoutIcon />,
      onClick: signout,
    }
  ];

  if (!isInQuickLink) return allOptions;
  return _.filter(allOptions, "inQuickLink");
};

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { user } = useAuth();
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const menuOptions = useMenuOptions();
  const pages = usePages();
  const location = useLocation();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" sx={{ width: "100%", backgroundColor: "white", paddingTop: { xs: ".5rem", sm: 0 } }} elevation={1}>
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Logo />
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color={primaryColor}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map(({ name, path }) => (
                <MenuItem key={name} onClick={handleCloseNavMenu}>
                  <Typography component={Link} to={path + location.search} textAlign="center" sx={{ textDecoration: 'none', color: "inherit" }}>
                    {name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Stack direction="row" spacing={1}>
              {pages.map(({ name, path }) => (
                <PageLink
                  key={name}
                  onClick={handleCloseNavMenu}
                  pageLink={{ name, path }}
                  style={{ padding: ".3rem", justifyContent: "center", color: primaryColor, border: "1px solid", borderColor: primaryColor, backgroundColor: "transparent" }}
                >
                  {name}
                </PageLink>
              ))}
            </Stack>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, color: primaryColor, borderRadius: 12, border: "1px solid" }}>
              <Avatar sx={{ backgroundColor: "transparent", color: primaryColor }}>{`${_.first(user.name)}${_.first(user.surname)}`}</Avatar>
            </IconButton>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
            {
              _.map(menuOptions, ({ name, icon, onClick }) => (
                <MenuItem key={name} onClick={() => { onClick(); handleCloseUserMenu(); }}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{name}</ListItemText>
                </MenuItem>
              )
            )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;