import { Link, Typography } from "@mui/material";
import { Checkbox } from "../../utils/form";

const Label = () => {
  return (
    <Typography>
      Acepto los <Link href="termsAndConditions" target="_blank">términos y condiciones</Link>
    </Typography>
  );
};

const TermsAndConditionsInput = props => {
  return (
    <Checkbox
      name="acceptsTermsAndConditions"
      label={<Label />}
      required
      {...props}
    /> 
  );
};

export default TermsAndConditionsInput;