import { ManualInput } from "../../../../../utils/form";

const MedicineManualInput = props => (
  <ManualInput
    label="Prescripción"
    {...props}
    name={`${props.name}.text`}
  />
);

export default MedicineManualInput;