import { Link } from "react-router-dom";
import { Card, CardMedia, CardContent, Typography, CardActionArea } from "@mui/material";
import { useAuth } from "../../../auth/context";

const TrialsButton = () => {
  const { user } = useAuth();
  if (!user.hasScope("trials")) return null;
  
  return (
    <Card sx={{ maxWidth: 345, height: "min-content" }}>
      <CardActionArea component={Link} to="trials">
        <CardMedia
          sx={{ height: 140 }}
          image="https://res.cloudinary.com/dw86qy8xu/image/upload/v1711238142/trials.jpg"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Estudios clínicos
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Usá esta opción para acceder a los estudios clínicos
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default TrialsButton;