import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import _ from "lodash";

const CustomTextField = ({ control, name, label, rules, required, ...props }) => {
  const controllerRules = rules || { required };
  return (
    <Controller
      control={control}
      name={name}
      rules={controllerRules}
      render={({
        field,
        fieldState: { error }
      }) => (
        <TextField
          error={!_.isEmpty(error)}
          variant="outlined"
          {...field}
          {...props}
          label={`${label}${controllerRules.required ? "*" : ""}`}
        />
      )
      }
    />
  )
};

export default CustomTextField;