import { useState, useEffect, useContext, createContext } from "react";
import _ from "lodash";

const EventsProxyContext = createContext();

export const EventsProxyProvider = ({ children }) => {
  const [promptInstall, setPromptInstall] = useState(null);

  const handler = e => {
    e.preventDefault();
    setPromptInstall(e);
  };

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", handler);
  }, []);

  const value = { promptInstall };

  return (
    <EventsProxyContext.Provider value={value}>
      {children}
    </EventsProxyContext.Provider>
  );
}

export const useEvents = () => useContext(EventsProxyContext);
