import { ErrorAlert } from "../../../../utils";
import { TextField } from "../../../../utils/form";
import { Button, Dialog, DialogTitle, Stack, Typography } from "@mui/material";
import _ from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import ContentInput from "./ContentInput";

const InstructionalVideos = ({ type }) => {
  const prescriptionsUrl = "https://drive.google.com/file/d/15aPZF0JjT3z_m8a5oP7a00oiqsKW98Qh/view?usp=share_link";
  const ordersUrl = "https://drive.google.com/file/d/1jt098n8N6gYvU8fgVSZSVuBbv2gtnAc2/view?usp=share_link";
  const videoUrl = type == "prescription" ? prescriptionsUrl : ordersUrl;
  return (
    <Typography>
      Si tiene dudas, puede consultar el <a target="_blank" href={videoUrl}>video instructivo</a>
    </Typography>
  );
};

const NameHelperText = ({ type }) => (
  <span>Debe ser único. Por ejemplo: {type === "prescription" ? "Medicacion HTA 1" : "RMN Cerebro simple" }</span>
);

const TemplateDialog = ({ originalTemplate, templates, type, open, toggleOpen, setSelectedTemplate, defaultContent, onSubmitOperation, afterSubmit, title, buttonText }) => {
  const defaultValues = originalTemplate ? _.pick(originalTemplate, "name", "type", "content") : { name: "", content: defaultContent, inputMethod: "text" };
  const form = useForm({ defaultValues });
  const [error, setError] = useState();
  const { handleSubmit, control } = form;

  const onSubmit = templateDto => {
    onSubmitOperation({ type, originalTemplate, templateDto })
    .tap(toggleOpen)
    .tap(afterSubmit)
    .tap(setSelectedTemplate)
    .tap(() => form.reset())
    .catch(setError);
  };

  const onSubmitWithoutPropagation = e => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit(onSubmit)(e);
  };

  const errorMessage = _.chain(error)
    .get("response.data.message.detail")
    .includes("unique")
    .value() ? "Ya existe una plantilla con este nombre" : "No se pudo crear la plantilla";

  return (
    <>
      <Dialog open={open} onClose={toggleOpen} fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <form onSubmit={onSubmitWithoutPropagation} autoComplete="off">
          <Stack spacing="1rem" p="1rem">
            <InstructionalVideos type={type} />
            <TextField control={control} name="name" label="Nombre de plantilla" variant="outlined" required helperText={<NameHelperText type={type} />} style={{ width: "100%" }} />
            <ContentInput type={type} form={form} originalTemplate={originalTemplate}/>
            <Button type="submit">{buttonText}</Button>
          </Stack>
        </form>
        <ErrorAlert
          show={!_.isEmpty(error)}
          onClose={() => setError(null)}
          message={errorMessage}
        />
      </Dialog>
    </>
  );
};

export default TemplateDialog;