import { useEffect, useState } from "react";
import { MethodPicker, TextField } from "../../../../utils/form";
import { useFieldArray } from "react-hook-form";
import { Button, Stack } from "@mui/material";
import _ from "lodash";

const options = [
  { label: "Texto", value: "text" },
  { label: "Casillas de verificación", value: "set" },
];

const ContentHelperText = ({ type }) => (
  <span>Por ejemplo: {type === "prescription" ? "Atenolol 5mg x 28 comprimidos" : "RMN cerebro sin contraste" }</span>
);

const TextInput = ({ form: { control }, type }) => {
  return (
    <TextField control={control} name="content" label="Indicaciones" variant="outlined" required helperText={<ContentHelperText type={type} />} multiline rows={4} style={{ width: "100%" }} />
  );
};

const OptionInput = props => {
  const { form: { control }, index } = props;
  const label = `Opción ${index + 1}`;
  return <TextField {...props} control={control} label={label} />;
};

const SetInput = ({ form, originalTemplate }) => {
  const { fields, append } = useFieldArray({
    control: form.control,
    name: "contents"
  });
  const { watch } = form;
  const { contents: watchedContents } = watch();

  const addOption = () => append('');

  useEffect(() => {
    if (!_.isEmpty(fields)) return;
    addOption();
  }, [fields]);

  useEffect(() => {
    const content = _.get(originalTemplate, "content", "");
    const contents = content.split(",");
    form.setValue("contents", contents);
  }, []);

  useEffect(() => {
    const options = _(watchedContents).compact().join(",");
    form.setValue("content", options);
  }, [JSON.stringify(watchedContents)]);

  return (
    <>
      <Stack>
      {
        _(fields)
        .map((field, index) => (
          <Stack key={index} py="1rem">
            <OptionInput form={form} name={`contents[${index}]`} required={index === 0} index={index} />
          </Stack>
        ))
        .value()
      }
      </Stack>
      <Button onClick={addOption}>Agregar opción</Button>
    </>
  );
};

const MethodBasedInput = ({ method, ...props }) => {
  return method === "text"
    ? <TextInput {...props} />
    : <SetInput {...props} />
};

const ContentInput = props => {
  const { originalTemplate } = props;
  const defaultMethod = originalTemplate?.inputMethod || "text";
  const [method, setMethod] = useState(defaultMethod);
  const { form } = props;

  useEffect(() => {
    form.resetField("content");
    form.resetField("contents");
    form.setValue("inputMethod", method);
  }, [method]);

  return (
    <>
      <MethodPicker method={method} setMethod={setMethod} options={options} />
      <MethodBasedInput {...props} method={method} />
      <input type="hidden" name="inputMethod" />
    </>
  );
};

export default ContentInput;