import * as yup from "yup";
import { text as textSchema } from "../schemas";

const passwordValidations = {
  password: textSchema
    .min(6, 'La contraseña debe tener al menos 6 caracteres'),
  passwordConfirmation: textSchema
    .oneOf([yup.ref('password')], 'Las contraseñas no coinciden'),
};

export default passwordValidations;