import { Stack } from "@mui/material";

const Logo = ({ width: settedWidth }) => {
  const width = settedWidth || "140px";
  return (
    <Stack display="flex" flex flexDirection="row" direction="row" textAlign="center">
      <img src="https://res.cloudinary.com/dw86qy8xu/image/upload/v1714348715/recetarioLoginLogo2.png" style={{ width }}/>
    </Stack>
  );
};

export default Logo;