import { useCallback, useEffect, useState } from "react";
import { Stack, Typography, Button, IconButton } from "@mui/material";
import { MethodPicker, Checkbox } from "../../../../../utils/form";
import ManualInput from "./ManualInput";
import _ from "lodash";
import NavigationBar from "../../../../../utils/wizard/navigation";
import { useFieldArray, useForm } from "react-hook-form";
import VademecumInput from "./vademecum";
import TemplatesMenu from "../../../../utils/medicalDocument/templates/menu";
import TemplateContentPicker from "../../../../utils/medicalDocument/templates/ContentPicker";
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useApi } from "../../../../../utils/hooks";

const currentStep = 2;

const LongTermCheckbox = ({ form, name }) => {
  return (
    <Checkbox
      form={form}
      name={`${name}.longTerm`}
      label="Tratamiento prolongado"
    />
  );
};

const DuplicatedCheckbox = ({ form, name }) => {
  return (
    <Checkbox
      form={form}
      name={`${name}.requiresDuplicate`}
      label="Duplicado"
    />
  );
};

const AllowsSubstitution = ({ form, name }) => {
  return (
    <Checkbox
      form={form}
      name={`${name}.observeBrand`}
      label="Respetar marca"
    />
  );
};

const RemoveButton = ({ form, name }) => {
  const onClick = () => {
    form.resetField(name, { defaultValue: _.cloneDeep(defaultMedicine) });
  };

  return (
    <IconButton onClick={onClick}>
      <DeleteIcon />
    </IconButton>
  );
};

const HivCheckbox = ({ form, error }) => {
  return (
    <>
      <Checkbox
        form={form}
        name="hiv"
        label="Paciente HIV"
      />
      {error && "Debe completar el género y fecha de nacimiento del paciente" }
    </>
  );
};

const templateType = "prescription";
const ManualInputWithTemplates = props => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const { name } = props;
  const medicineFieldName = `${name}.text`;
  const api = useApi();

  useEffect(() => {
    api.templates(templateType)
    .tap(setTemplates);
  }, []);

  const defaultTemplateContent = defaultMedicine.text;

  return (
    <Stack spacing="1rem">
      <TemplatesMenu
        templates={templates}
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={setSelectedTemplate}
        defaultContent={defaultTemplateContent}
        type={templateType}
      />
      <TemplateContentPicker
        selectedTemplate={selectedTemplate}
        {...props}
        fieldName={medicineFieldName}
        defaultContent={defaultTemplateContent}
      />
      <ManualInput {...props} />
    </Stack>
  );
};

const MedicineInput = ({ method, ...props }) => {
  return <Stack spacing={2}>
    <Stack direction="row" justifyContent="space-between">
      <Stack direction={{ xs: "column", sm: "row" }}>
        <LongTermCheckbox {...props} />
        <DuplicatedCheckbox {...props} />
        <AllowsSubstitution {...props} />
      </Stack>
      <RemoveButton {...props} />
    </Stack>
    {
      method === "manual" ? <ManualInputWithTemplates {...props} />
      : <VademecumInput {...props} />
    }
  </Stack>
};

const defaultMedicine = { text: '', longTerm: false, quantity: null, externalId: null, requiresDuplicate: false, observeBrand: false, posology: '' };
const MedicineForm = ({ onFinish, fullForm, ...props }) => {
  const [method, setMethod] = useState("vademecum");
  const form = useForm({ defaultValues: { medicines: [defaultMedicine], hiv: false } });
  const { handleSubmit } = form;
  const { fields, append } = useFieldArray({
    control: form.control,
    name: "medicines",
  });
  const currentForm = form.watch();
  
  const addMedicine = () => append(_.cloneDeep(defaultMedicine));
  
  const validHivData = !currentForm.hiv || (fullForm?.patient?.birthDate && fullForm?.patient?.gender);
  const someMedicineSelected = _.some(currentForm.medicines, medicine => medicine.text);
  const canContinue = someMedicineSelected && validHivData;
  useEffect(() => {
    form.reset();
  }, [method]);
  
  const onSubmit = useCallback(({ medicines: allMedicines, hiv }) => {
    //TODO append(defaultMedicine) is setting default values but not getting here, only on vademecum :(
    const medicinesWithRepeated = _(allMedicines)
      .filter("text")
      .map(medicine => _.assign({}, defaultMedicine, medicine))
      .value();

    const medicines = method != "manual" ? _.uniqBy(medicinesWithRepeated, "externalId") : medicinesWithRepeated;
    
    onFinish({ method, medicines, hiv });
    props.nextStep();
  }, [method]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Stack spacing="1rem">
        <Stack>
          <Typography variant="h5" gutterBottom>Medicamentos</Typography>
          <MethodPicker method={method} setMethod={setMethod} />
        </Stack>
        <Stack>
          {
            method === "manual" &&
              <Typography mb="1rem" color="error">
                Recomendamos el uso de Vademecum para maximizar la aceptación en farmacias.
                Además, no será validada por OSDE
              </Typography>
          }
          {
            _(fields)
            .map((field, index) => (
              <Stack key={index} py="1rem">
                <MedicineInput form={form} name={`medicines[${index}]`} method={method} required={index === 0} />
              </Stack>
            ))
            .value()
          }
          {
            _.size(fields) < 3 && <Button onClick={addMedicine}>Agregar medicamento</Button>
          }
          <HivCheckbox form={form} error={!validHivData} />
        </Stack>
      </Stack>
      <Stack pb="1rem">
        <NavigationBar step={currentStep} nextDisabled={!canContinue} {...props}/>
      </Stack>
    </form>
  );
};

export default MedicineForm;