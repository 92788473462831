import { TextField } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Controller } from "react-hook-form";
import _ from "lodash";
import 'moment/locale/es';

//TODO minDate is ok but controller's value is not changing after first render
const CustomDatePicker = ({ form: { control }, name, label, required, minDate, maxDate, manualInput }) => {

  const preventManualTextInput = (e) => {
    if (manualInput) return;
    e.preventDefault();
  };
  
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required
      }}
      render={({
        field: { value, onChange },
        fieldState: { error }
      }) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label={label}
            value={value}
            onChange={date => onChange(date.format("YYYY-MM-DD"))}
            minDate={minDate}
            maxDate={maxDate}
            inputFormat="DD/MM/YYYY"
            renderInput={(params) => <TextField fullWidth onKeyDown={preventManualTextInput} {...params} error={!_.isEmpty(error)} />}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default CustomDatePicker;