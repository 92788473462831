import _ from "lodash";
import { Typography, CircularProgress, TablePagination, Table, TableBody, TableContainer, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import CustomTableHead from "./CustomTableHead";
import { Box } from "@mui/material";

function PaginatedTable({
  rows,
  columns,
  pagination = {},
  RowGetter,
  notRowsMessage,
  toRowOrderField,
  loading,
  defaultSortingFieldId,
  handleRequestSort = () => {},
}) {
  const {
    count,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    rowsPerPage,
  } = pagination;
  const [sortingField, setSortingField] = useState({
    direction: "desc",
    id: defaultSortingFieldId,
  });

  useEffect(() => {
    const offset = page * rowsPerPage;
    if (offset > count) handleChangePage(undefined, page - 1);
  }, [rowsPerPage, count, page]);

  function _handleRequestSort(event, property) {
    const id = property;
    let direction = "desc";

    if (sortingField.id === property && sortingField.direction === "desc") {
      direction = "asc";
    }
    const newSortField = { direction, id };
    setSortingField(newSortField);
    handleRequestSort(newSortField);
  }

  if (loading) {
    return (
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        marginTop: "14rem",
      }} >
        <CircularProgress />
      </Box>
    );
  }

  if (_.isEmpty(rows)) {
    return (
      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "5rem" }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.1 } }}
      >
        <Typography color="textSecondary" variant="h5">
          {notRowsMessage}
        </Typography>
      </Box>
    );
  }

  const orderedRows = _.orderBy(
    rows,
    [(row) => toRowOrderField(row, sortingField)],
    [sortingField.direction]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-end",
        marginTop: "2rem",
        width: "100%",
        boxSizing: "border-box",
        paddingTop: "1rem",
      }}
    >
      <TableContainer component={Paper} style={{ maxHeight: 600 }}>
        <Table
          stickyHeader
          aria-labelledby="tableTitle"
        >
          <CustomTableHead
            sortingField={sortingField}
            onRequestSort={_handleRequestSort}
            rows={columns}
          />
          <TableBody>{_.map(orderedRows, row => <RowGetter key={row.id} row={row} />)}</TableBody>
        </Table>
      </TableContainer>
      {!_.isEmpty(pagination) && (
        <TablePagination
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Filas por página:"
          backIconButtonProps={{
            "aria-label": "página anterior",
            title: "Ir a la página anterior",
          }}
          nextIconButtonProps={{
            "aria-label": "página siguiente",
            title: "Ir a la página siguiente",
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
}

export default PaginatedTable;
