import { TextField } from "../../../../utils/form";

const Password = ({ form: { control }, label = "Contraseña" }) => {
  return (
    <>
      <TextField control={control} name="password" type="password" label={label} variant="outlined" required helperText="Al menos 6 caracteres" style={{ width: "100%" }} />
      <TextField control={control} name="passwordConfirmation" type="password" label="Repita la contraseña" variant="outlined" required style={{ width: "100%" }} />
    </>
  );
};

export default Password;