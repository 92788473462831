import { Autocomplete, TextField } from "@mui/material";
import { matchSorter } from 'match-sorter'
import _ from "lodash";

const SelectWithFilter = ({ label, name, onChange, error, helperText, required, list, onPicked = _.identity, minInputLength, matcherOptions, renderInputProps, ...props }) => {
  const labelId = `${name}-label`;
  const labelWithRequired = `${label}${required ? "*" : ""}`;

  const handleFilterOptions = (options, { inputValue }) => {
    if (inputValue.length < minInputLength) return [];

    return matchSorter(options, inputValue, matcherOptions);
  };

  const filterOptions = minInputLength && { filterOptions: handleFilterOptions };

  return (
    <Autocomplete
      disablePortal
      id={labelId}
      onChange={(event, newValue) => {
        onChange(newValue);
        if (event.type !== "click" && event.key !== "Enter") return;
        onPicked(newValue);
      }}
      onInputChange={(event, newValue) => {
        if (!props.freeSolo) return;
        onChange(newValue);
      }}
      options={list}
      renderInput={(params) => <TextField {...params} {...renderInputProps} label={labelWithRequired} error={!_.isEmpty(error)} helperText={helperText} />}
      noOptionsText="No hay opciones que coincidan con la búsqueda"
      renderOption={(props, value) => {
        return (
          <li {...props} key={_.get(value, "id", value)}>
            {props.key}
          </li>
        );
        }}
      loadingText="Cargando..."
      {...filterOptions}
      {...props}
    />
  );
};

export default SelectWithFilter;