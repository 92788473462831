import { Stack } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import SurnameInput from "../utils/medicalDocument/form/patient/SurnameInput";
import { useForm } from "react-hook-form";
import MedicalDocumentsList from "../utils/medicalDocument/list/index";
import Promise from "bluebird";
import NewPatientButton from "../utils/patient/new/Button";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../utils/hooks";

//TODO avoid repeating with medicalDocument/form/patient/index.jsx
const defaultPatient = { id: "", surname: "" };
const PatientPicker = ({ setPatient }) => {
  const [patients, setPatients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const form = useForm({ defaultValues: defaultPatient });
  const patient = form.watch();
  const api = useApi();

  useEffect(() => {
    api.patients()
      .tap(setPatients)
      .tap(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setPatient(patient);
  }, [JSON.stringify(patient)]);

  return (
    <SurnameInput form={form} patients={patients} helperText="" loading={isLoading} />
  );
};

const Patients = () => {
  const api = useApi();
  const [patient, setPatient] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const paginationOptions = { page, rowsPerPage: Math.floor(rowsPerPage / 2) };

  const mergeMedicalDocuments = (medicalDocumentsByType) => {
    const combinedData = _.flatMap(medicalDocumentsByType, doc => doc.data);
    const totalItems = _.reduce(medicalDocumentsByType, (sum, doc) => sum + doc.meta.totalItems, 0);

    return { data: combinedData, meta: { totalItems: totalItems } };
  };

  const getMedicalDocuments = async () => {
    const filters = patient.id && { patient: { id: patient.id } }
    const documentTypes = ["prescription", "order"];
    return Promise.map(documentTypes, type => api.medicalDocuments(`${type}s`, { filters, ...paginationOptions }))
      .then(mergeMedicalDocuments)
  }

  const { isLoading: loading, data: { data: medicalDocuments, meta: { totalItems } = {} } = {} } = useQuery({
    queryKey: ["medicalDocuments", patient.id, paginationOptions],
    queryFn: () => getMedicalDocuments(),
    keepPreviousData: true,
    staleTime: Infinity
  });

  return (
    <Stack spacing={3} sx={{ width: "100%", height: "100%", px: { xs: "1rem", md: "4rem" }, pt: "2rem", boxSizing: "border-box", overflowY: "auto" }}>
      <Stack direction="row" spacing={2}>
        <Stack sx={{ width: { xs: "80%", sm: "100%" } }}>
          <PatientPicker setPatient={setPatient} />
        </Stack>
        <NewPatientButton direction="down" />
      </Stack>
      <MedicalDocumentsList
        medicalDocuments={medicalDocuments}
        loading={loading}
        page={page}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setPage={setPage}
        count={totalItems}
      />
    </Stack>
  );
};

export default Patients;