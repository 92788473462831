import _ from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PatientCreator from "../utils/patient/new/PatientCreator";
import { ErrorAlert } from "../../utils";
import { useApi } from "../../utils/hooks";

const NewPatient = () => {
  const api = useApi();
  const title = `Nuevo paciente`;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const onAlertClose = () => setError(null);
  
  const onSubmit = patient => {
    setLoading(true);
    api.createPatient(patient)
    .tap(() => navigate("/"))
    .catch(setError)
    .tap(() => setLoading(false));
  };
  
  return (
    <>
      <PatientCreator onSubmit={onSubmit} loading={loading} title={title} />
      <ErrorAlert
        show={!_.isEmpty(error)}
        onClose={onAlertClose}
        message="Ya existe un paciente con este DNI"
      />
    </>
  );
};

export default NewPatient;