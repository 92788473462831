import { Button } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";

const PageLink = ({ pageLink: { name, path }, ...props }) => {
  const { search } = useLocation();

  return (
    <NavLink to={{ pathname: path, search }}>
      {
        ({ isActive }) => (
          <Button fullWidth sx={{ backgroundColor: isActive ? "rgb(195 221 248)" : "none", textTransform: "none", fontSize: "1rem", color: "black", justifyContent: "start", pl: "1rem" }} {...props}>
            {name}
          </Button>
        )
      }
    </NavLink>
  )
};

export default PageLink;