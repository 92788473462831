import NewPatientButton from '../utils/patient/new/Button';

const HomeNewPatientButton = () => {

  return (
    <NewPatientButton
      sx={{
        position: "fixed", top: "auto",
        bottom: { xs: "1rem", sm: "3rem" },
        left: "auto",
        right: { xs: "1rem", sm: "3rem" }
      }}
      direction="up" />
  );
};

export default HomeNewPatientButton;