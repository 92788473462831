export default [
  {
    name: "Laboratorio",
    type: "order",
    content: "Hemograma,Urea y creatinina,Hepatograma,Glucemia,Ionograma,ESD y PCR,Coagulograma,Colesterol total / LDL / HDL / Triglicéridos,Grupo y factor,Ácido úrico,Beta HCG,HBa1 glicosilada,Prueba de tolerancia oral a la glucosa,Insulinemia,Péptido C,Lipoproteína A,Apo A,Apo B,Dímero D,Fibrinógeno,Hemocultivos,Urocultivo,Orina completa,Leucocitos en MF,Coprocultivo,Dosaje vitamina B12,Dosaje vitamina C,Dosaje vitamina D,Homocisteína,Ferremia,Ferritina,Saturación de transferrina,Amilasa,Lipasa,GGT,5 nucleotidasa,LDH,FAL,Troponina T,CPK,HIV,VDRL,FTAbs,Serología Hep B (AntiHBs),HBsAg,AntiHBc,Serología Hep C (AntiHCV),PCR Hep C,PPD 2UT,Anticuerpos COVID-19,PCR COVID-19,Serología Hep A (IgG),Sarampión IgG,Varicela IgG,Parotiditis IgG,Rubeola IgG,Calcemia,Fósforo,Magnesio,Zinc,Selenio,Cortisol,Estradiol,Estrona,Estriol,Testosterona total,Testosterona libre,PTH,Proteína ligadora de hormonas sexuales,FSH,LH,TSH,T3,T3 libre,T4,T4 libre,Prolactina,DHEA S,CA 125,CA 19-9,CA 15-3,CEA,PSA total y libre,Ac antiperoxidasa,Ac antitiroglobulina,IgA total,IgG / IgA antiendomisio,IgG / IgA antigliadina,IgG/ IgA antitransglutaminasa,IgG / IgA antipéptido deaminados de gliadina",
    inputType: "set"
  },
  {
    name: "Ecografía",
    type: "order",
    content: "Abdominal,Vesico-prostática,Ginecológica TV,Hepatobiliopancreática,Renal,Ginecológica transparietal,Partes blandas,Tiroidea,Mamaria",
    inputType: "set"
  }
]