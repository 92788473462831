import _ from "lodash";
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import { ContentCopy as ContentCopyIcon, Close as CloseIcon } from '@mui/icons-material';
import { useEffect, useRef, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import QRCode from "react-qr-code";
import { useReactToPrint } from 'react-to-print';
import { Alert, LoadingSpinner } from "../../../../utils";
import PrintableQr from "./PrintableQr";
import { useApi } from "../../../../utils/hooks";

const ShareLink = ({ selfRegisterUrl }) => {
  const [copiedLink, setCopiedLink] = useState(false);
  const copiedLinkMessage = "Link copiado al portapapeles";
  const onAlertClose = () => setCopiedLink(false);

  return (
    <>
      <Typography>
        Compartile este link al paciente para que se registre en tu recetario:
      </Typography>
      <Stack direction="row" paddingX="5px">
        <Typography variant="body2" color="text.secondary" style={{ wordWrap: "break-word" }} maxWidth="100%">
          {selfRegisterUrl}
        </Typography>
        <CopyToClipboard text={selfRegisterUrl} onCopy={() => setCopiedLink(true)}>
          <IconButton >
            <ContentCopyIcon />
          </IconButton>
        </CopyToClipboard>
      </Stack>
      <Alert severity="success" message={copiedLinkMessage} show={copiedLink} duration={3000} onClose={onAlertClose} />
    </>
  );
}

const ShareQRCode = ({ selfRegisterUrl }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Typography>
        También puede registrarse escaneando el siguiente QR:
      </Typography>
      <div style={{ maxWidth: "200px", margin: "0 auto", marginTop: "1rem" }}>
        <QRCode style={{ width: "100%", height: "auto" }} value={selfRegisterUrl} />
        <PrintableQr url={selfRegisterUrl} reference={componentRef} />
      </div>
      <Button variant="contained" onClick={handlePrint} style={{ textTransform: "none", width: "200px", alignSelf: "center" }}>
        Imprimir
      </Button>
    </>
  );
}

const ShareRegistrationDialog = ({ open, toggleOpen }) => {
  const api = useApi();
  const [token, setToken] = useState();
  const baseUrl = window.location.origin;
  const selfRegisterUrl = token && baseUrl + `/patients/selfRegister?token=${token}`;

  useEffect(() => {
    if (!open) return;
    api.patientRegistrationToken()
      .tap(setToken)
  }, [open]);

  return (
    <Dialog open={open} onClose={toggleOpen}>
      <DialogTitle>Link para registro del paciente</DialogTitle>
      <IconButton
        onClick={toggleOpen}
        sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack p="1rem" spacing="1rem">
          {token ?
            <>
              <ShareLink selfRegisterUrl={selfRegisterUrl} />
              <ShareQRCode selfRegisterUrl={selfRegisterUrl} />
            </>
          : <Stack alignItems="center"><LoadingSpinner isLoading /></Stack>
          }
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ShareRegistrationDialog;