import { LoadingButton } from "@mui/lab";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import profileValidations from "./schema";
import Fields from "./Fields";
import { Alert, ErrorAlert } from "../../utils";
import { useAuth } from "../../auth/context";
import { toDirtyValues } from "../../utils/form";
import DoctorInfo from "./DoctorInfo";
import { useApi } from "../../utils/hooks";

const errorMessage = "No pudimos actualizar tus datos. Por favor, intentalo más tarde"
const successMessage = "Tus datos fueron actualizados con éxito";

const formSchema = yup.object().shape(profileValidations);

const Settings = () => {
  const { user } = useAuth();
  const form = useForm({
    defaultValues: _.pick(user.profile, _.keys(profileValidations)),
    resolver: yupResolver(formSchema),
    context: { user }
  });
  const [ updateStatus, setUpdateStatus ] = useState(null);
  const [ getSignatureStatus, setGetSignatureStatus ] = useState(null);
  const { handleSubmit, formState: { dirtyFields } } = form;
  const api = useApi();
  
  const onAlertClose = () => setUpdateStatus(null);
  const isLoading = updateStatus === "loading";
  const failed = updateStatus === "error";
  const succeeded = updateStatus === "success";

  const signatureLoading = getSignatureStatus === "loading";

  useEffect(() => {
    setGetSignatureStatus("loading")
    api.signature()
    .tap(signature => form.setValue("signature", signature))
    .tap(() => setGetSignatureStatus("success"))
    .catch(() => setGetSignatureStatus("error"));
  }, []);
  
  const updateUser = useCallback(fullProfile => {
    const profileToUpdate = toDirtyValues(dirtyFields, fullProfile);
    setUpdateStatus("loading");
    api.updateUser({ profile: { ..._.omit(profileToUpdate, "signature"), id: user.profile.id }, signature: profileToUpdate.signature })
    .then(() => {
      setUpdateStatus("success");
    })
    .catch(() => {
      setUpdateStatus("error");
    });
  }, [dirtyFields]);

  const canSave = !_.isEmpty(dirtyFields);

  return (
    <Stack display="flex" flexDirection="column" alignItems="center" spacing="2rem" p="2rem" width="100%" boxSizing="border-box">
      <Box>
        <Typography variant="h4">Ajustes</Typography>
      </Box>
      <form onSubmit={handleSubmit(updateUser)} autoComplete="off" style={{ width: "100%" }}>
        <Stack alignItems="center" spacing="2rem">
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={12} md={10} lg={8} xl={6}>
              <Stack spacing="2rem">
                <DoctorInfo />
                <Fields form={form} signatureLoading={signatureLoading} />
              </Stack>
            </Grid>
          </Grid>
          <LoadingButton type="submit" variant="contained" loading={isLoading} disabled={!canSave}>
            Guardar
          </LoadingButton>
        </Stack>
      </form>
      <ErrorAlert
        show={failed}
        onClose={onAlertClose}
        message={errorMessage}
      />
      <Alert
        severity="success"
        message={successMessage}
        show={succeeded}
        onClose={onAlertClose}
        duration={2000}
      />
    </Stack>
  );
};

export default Settings;