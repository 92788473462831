import { SelectWithFilter } from "../../../../utils/form";
import healthInsurances from "./healthInsurances";

const HealthInsuranceSelect = ({ form: { control } }) => (
  <SelectWithFilter
    control={control}
    required
    name="healthInsurance"
    label="Obra social/prepaga"
    helperText="Sin el código de plan. Si es particular, seleccioná 'Particular'"
    pattern={/^[A-Za-zÁÉÍÓÚáéíóú\s.&(),'-]+$/}
    list={healthInsurances}
  />
);

export default HealthInsuranceSelect;