import { useApi } from "../../../../utils/hooks";
import TemplateDialog from "./TemplateDialog";

const TemplateCreator = ({ templates, ...props }) => {
  const title = "Nueva plantilla";
  const buttonText = "Crear";
  const api = useApi();

  const createTemplate = ({ type, templateDto }) => api.createTemplate(type, templateDto);

  const addToTemplates = template => templates.push(template);

  return (
    <TemplateDialog
      onSubmitOperation={createTemplate}
      afterSubmit={addToTemplates}
      templates={templates}
      title={title}
      buttonText={buttonText}
      {...props}
    />
  );
};

export default TemplateCreator;