import NewMedicalDocument from "../../utils/medicalDocument/new";
import MedicineForm from "./form/medicine";

const PrescriptionCreator = () => (
  <NewMedicalDocument
    module={{ name: "receta", basePath: "prescriptions" }}
    MedicineForm={MedicineForm}
  />
)

export default PrescriptionCreator;