import { ToggleButtonGroup, ToggleButton, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import _ from "lodash";

const GenderField = ({ control, name, label, rules, required, helperText, ...props }) => {
  const controllerRules = rules || { required };
  return (
    <Controller
      control={control}
      name={name}
      rules={controllerRules}
      render={({
        field,
      }) => (
        <>
          <ToggleButtonGroup
            exclusive
            aria-label="gender"
            {...field}
            {...props}
          >
            <ToggleButton value="m">M</ToggleButton>
            <ToggleButton value="f">F</ToggleButton>
            <ToggleButton value="o">Otro</ToggleButton>
          </ToggleButtonGroup>
          {helperText && 
            <Typography variant="caption" color="textSecondary">
              {helperText}
            </Typography>}
        </>
      )
      }
    />
  )
};

export default GenderField;