import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import { LoadingButton } from "@mui/lab";
import PatientForm from "../form";
import SurnameInput from "./SurnameInput";

const PatientCreator = ({ onSubmit, title, loading, contactRequired }) => {
  
  return (
    <Stack spacing={2} p="2rem">
      <Typography variant="h5" gutterBottom textAlign="center"> {title} </Typography>
      <PatientForm onSubmit={onSubmit} SurnameInput={SurnameInput} contactRequired={contactRequired}>
        <Stack direction="row" justifyContent="center" pt="2rem">
          <LoadingButton type="submit" variant="contained" loading={loading}>
            Guardar
          </LoadingButton>
        </Stack>
      </PatientForm>
    </Stack>
  );
};

export default PatientCreator;