
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../auth/context";
import { Box, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ErrorAlert, Alert } from "../../utils";
import Fields from "./Fields";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";
import schema from "./schema";

const defaultErrorMessage = "No pudimos crear el usuario. Por favor, escribinos a consultas@recetario.com.ar";
const alreadyExistsErrorMEssage = "Ya existe una cuenta creada con este email. Por favor, escribinos a consultas@recetario.com.ar";

const Register = () => {
  const formSchema = yup.object().shape(schema);

  const form = useForm({
    defaultValues: formSchema.cast(),
    resolver: yupResolver(formSchema)
  });

  const { handleSubmit } = form;

  const [ loginStatus, setLoginStatus ] = useState(null);
  const [ error, setError ] = useState(null);
  const auth = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  
  const register = useCallback(userToCreate => {
    const healthCenterId = searchParams.get("healthCenterId");
    const signupBody = _.assign({}, userToCreate, { healthCenterId });

    setLoginStatus("loading");
    auth.signup(signupBody)
    .then(() => {
      setLoginStatus("success")
      setTimeout(() => {
        navigate('/login')
      }, 10000);
    })
    .catch(error => {
      setError(error);
      setLoginStatus("error");
    });
  }, [searchParams]);
  

  const onAlertClose = () => setError(null);
  const isLoading = loginStatus === "loading";
  const failed = !_.isEmpty(error);
  const errorMessage = _.get(error, "response.data.message") == "Entity already exists" ? alreadyExistsErrorMEssage : defaultErrorMessage;
  const succeeded = loginStatus === "success";

  return (
    <Stack display="flex" flexDirection="column" alignItems="center" spacing="2rem" p="2rem" width="100%" boxSizing="border-box">
      <Box>
        Crear una cuenta
      </Box>
      <form onSubmit={handleSubmit(register)} autoComplete="off" style={{ width: "100%" }}>
        <Stack display="flex" flexDirection="column" alignItems="center" spacing="2rem">
          <Fields form={form} />
          <LoadingButton type="submit" variant="contained" sx={{paddingX: "6rem"}} loading={isLoading} disabled={succeeded}>
            Crear cuenta
          </LoadingButton>
        </Stack>
      </form>
      <ErrorAlert
        show={failed}
        onClose={onAlertClose}
        message={errorMessage}
      />
      <Alert
        severity="success"
        message="Listo! Estamos validando tus datos. En las próximas 24hs hábiles recibirás un correo de confirmación para poder iniciar sesión."
        show={succeeded}
        duration={10000}
      />
    </Stack>
  );
};

export default Register;