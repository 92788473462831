import { TextField } from ".";

const AlphanumericField = props => (
  <TextField
    rules={{ required: props.required, pattern: { value: /^[a-zA-Z0-9]*$/ } }}
    helperText="Números o letras (sin espacios, guiones o caracteres especiales)"
    {...props}
  />
);

export default AlphanumericField;