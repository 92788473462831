import { Link } from "react-router-dom";
import { Card, CardMedia, CardContent, Typography, CardActionArea } from "@mui/material";

const NewPrescriptionButton = () => (
  <Card sx={{ maxWidth: 345, height: "min-content" }}>
    <CardActionArea component={Link} to="prescriptions/new">
      <CardMedia
        sx={{ height: 140 }}
        image="https://res.cloudinary.com/dw86qy8xu/image/upload/v1711238056/prescriptions.jpg"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Recetas
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Usá esta opción para generar una nueva receta
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
);

export default NewPrescriptionButton;