
import { Checkbox, FormControlLabel, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";
import _ from "lodash";


const CustomCheckbox = ({ name, label, form: { control, formState }, required }) => (
  <>
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          rules={{
            required
          }}
          render={({ field }) => (
            <Checkbox
              {...field}
              checked={field.value}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          )}
        />
      }
      label={label}
      style={{
        width: "fit-content"
      }}
    />
    {_.get(formState.errors, name) && (
      <FormHelperText error style={{ marginTop: 0 }}>{_.get(formState.errors, `${name}.message`)}</FormHelperText>
    )}
  </>
);

export default CustomCheckbox;