const columns = [
  {
    id: 'id',
    label: 'Id',
    align: 'center',
    disablePadding: false,
    sort: true,
  },
  {
    id: 'name',
    label: 'Nombre y apellido',
    align: 'center',
    disablePadding: false,
    sort: true,
  },
  {
    id: 'documentNumber',
    label: 'Documento',
    align: 'center',
    disablePadding: false,
    sort: true,
  },
  {
    id: 'type',
    label: 'Tipo',
    align: 'center',
    disablePadding: false,
    sort: true,
  },
  {
    id: 'content',
    label: 'Contenido',
    align: 'center',
    disablePadding: false,
    sort: false,
  },
  {
    id: 'diagnosis',
    label: 'Diagnóstico',
    align: 'center',
    disablePadding: false,
    sort: false,
  },
  {
    id: 'date',
    label: 'Fecha',
    align: 'center',
    disablePadding: false,
    sort: true,
  },
  {
    id: 'actions',
    label: 'Acciones',
    align: 'center',
    disablePadding: false,
    sort: false,
  },
];

export default columns;