import { useAuth } from "../../auth/context";
import _ from "lodash";

const Logo = () => {
  const { user } = useAuth();
  const healthCenter = _.get(user, "profile.healthCenter");
  if (_.isEmpty(healthCenter)) return;

  return <div style={{ textAlign: "center" }}>
    <img src={healthCenter.logoUrl} />
  </div>
};

export default Logo;