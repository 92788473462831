import * as yup from 'yup';
import { email as emailSchema, picture as pictureSchema } from '../../utils/form/schemas';
import { password, doctor } from '../../utils/form/validations';

const registerSchema = {
  email: emailSchema,
  ...password,
  ...doctor,
  license: pictureSchema,
  doctorWithLicense: pictureSchema,
  acceptsTermsAndConditions: yup.boolean()
    .default(false)
    .oneOf([true], 'Debe aceptar los términos y condiciones')
};

export default registerSchema;