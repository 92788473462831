import { useSearchParams } from "react-router-dom";
import { Phone } from "../../utils/user/form";
import _ from "lodash";
import { useEffect } from "react";

const RegisterPhone = props => {
  const [searchParams] = useSearchParams();
  const phone = searchParams.get("phone");

  useEffect(() => {
    const { form } = props;
    const { setValue, getValues } = form;
    const currentPhone = getValues("phone");
    if (currentPhone || !phone) return;

    setValue("workPhone", phone);
  }, []);

  const disabled = !_.isEmpty(phone);
  return <Phone {...props} disabled={disabled} />;
};

export default RegisterPhone;