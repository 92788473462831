import { useState } from "react";
import _ from "lodash";
import { IconButton, TableRow, TableCell, Stack, Typography, Tooltip } from "@mui/material";
import columns from "./columns";
import PaginatedTable from "../../../../utils/table/PaginatedTable";
import moment from "moment";
import { EventRepeat as RepeatIcon, Share as ShareIcon } from '@mui/icons-material';
import Repeater from "./Repeater";
import Exporter from "../exporter";
import { useAuth } from "../../../../auth/context";

const notRowsMessage = "No hay recetas/órdenes para mostrar";

const Content = ({ medicalDocument, truncated }) => {
  const { medicines } = medicalDocument;
  const medicinesToShow = _.isEmpty(medicines) ? [{ text: medicalDocument.medicine }] : medicines;

  return (
    <Stack spacing={1}>
      {
        _.map(medicinesToShow, ({ text, longTerm, observeBrand }, index) => (
          <Stack key={index}>
            <Typography>{truncated ? _.truncate(text, 30) : text}</Typography>
            {longTerm && <Typography>Tratamiento prolongado</Typography>}
            {observeBrand && <Typography>Respeta marca</Typography>}
          </Stack>
        )) 
      }
    </Stack>
  );
};

//TODO avoid repeating type translation
const RowGetter = ({ row: medicalDocument, count }) => {
  const { user } = useAuth();
  const [exporterOpen, setExporterOpen] = useState(false);
  const [repeaterOpen, setRepeaterOpen] = useState(false);
  const { id, patient, type, diagnosis, date } = medicalDocument;

  const toggleExporter = () => setExporterOpen(exporterOpen => !exporterOpen);
  const toggleRepeater = () => setRepeaterOpen(repeaterOpen => !repeaterOpen);
  
  const actions = [
    {
      name: "repeat",
      icon: <RepeatIcon />,
      onClick: toggleRepeater
    },
    {
      name: "export",
      icon: <ShareIcon />,
      onClick: toggleExporter
    }
  ];

  const actionsToShow = _.filter(actions, action => user.hasPermissionFor(action.name));

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={id}
      >
        <TableCell component="th" scope="row" align="center">
          {id}
        </TableCell>

        <TableCell component="th" scope="row" align="center">
          {patient.name} {patient.surname}
        </TableCell>

        <TableCell component="th" scope="row" align="center">
          {patient.documentNumber}
        </TableCell>

        <TableCell component="th" scope="row" align="center">
          {type == "prescription" ? "receta" : "orden"}
        </TableCell>

        <Tooltip title={<Content medicalDocument={medicalDocument} />} placement="top" enterTouchDelay={0} sx={{ cursor: "pointer" }}>
          <TableCell component="th" scope="row" align="center">
            <Content medicalDocument={medicalDocument} truncated />
          </TableCell>
        </Tooltip>

        <TableCell component="th" scope="row" align="center">
          {diagnosis}
        </TableCell>

        <TableCell component="th" scope="row" align="center">
          {moment(date).format("DD/MM/YYYY")}
        </TableCell>

        <TableCell
          component="th"
          scope="row"
          align="center"
        >
          <Stack direction="row" justifyContent="center">
            {_.map(actionsToShow, ({ name, icon, onClick }) => (
              <IconButton key={name} onClick={onClick}>
                {icon}
              </IconButton>
            ))}
          </Stack>
        </TableCell>
      </TableRow>
      {repeaterOpen && <Repeater medicalDocument={medicalDocument} onClose={toggleRepeater} />}
      {exporterOpen && <Exporter medicalDocument={medicalDocument} onClose={toggleExporter} />}
    </>
  );
};

const PatientsTable = ({ medicalDocuments, loading, page, rowsPerPage, setRowsPerPage, setPage, count }) => {
  function toRowOrderField(medicalDocument, sortingField) {
    const sortingFieldId = sortingField.id;
    const { id } = medicalDocument;
    switch (sortingFieldId) {
      case "id": {
        return parseInt(id, 10);
      }
      default: {
        return _.get(medicalDocument, sortingFieldId);
      }
    }
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
  }

  function handleChangePage(event, value) {
    setPage(value);
  }

  const pagination = { page, rowsPerPage, handleChangeRowsPerPage, handleChangePage, count };

  return (
    <PaginatedTable
      rows={medicalDocuments}
      columns={columns}
      RowGetter={RowGetter}
      notRowsMessage={notRowsMessage}
      toRowOrderField={toRowOrderField}
      loading={loading}
      pagination={pagination}
    />
  );
}

export default PatientsTable;
