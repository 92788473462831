import { TextField } from ".";

const NumberField = props => (
  <TextField
    rules={{ required: props.required, pattern: { value: /^[0-9]\d*$/ } }}
    helperText="Solo números (sin espacios, guiones o caracteres especiales)"
    {...props}
  />
);

export default NumberField;