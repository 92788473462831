
import { useCallback, useState } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { ErrorAlert } from "../../../utils";
import { PatientForm, DiagnosisForm, DateForm } from "./form";
import StepWizard from "react-step-wizard";
import transitions from './transitions.less';
import _ from "lodash";
import Exporter from "./exporter";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useApi } from "../../../utils/hooks";

const transitionsOptions = {
  enterRight: `${transitions.animated} ${transitions.enterRight}`,
  enterLeft: `${transitions.animated} ${transitions.enterLeft}`,
  exitRight: `${transitions.animated} ${transitions.exitRight}`,
  exitLeft: `${transitions.animated} ${transitions.exitLeft}`,
  intro: `${transitions.animated} ${transitions.intro}`,
};

const NewMedicalDocument = ({ module, MedicineForm }) => {
  const { name, basePath } = module;
  const [ createStatus, setCreateStatus ] = useState(null);
  const [ form, setForm ] = useState(null);
  const [ createdMedicalDocument, setCreatedMedicalDocument ] = useState(null);
  const [ error, setError ] = useState(null);
  const navigate = useNavigate();
  const api = useApi();
  const [searchParams] = useSearchParams();

  const onFinish = (partialForm) => setForm(form => ({ ...form, ...partialForm }));
  const onAlertClose = () => setCreateStatus(null);

  const create = useCallback((lastForm) => {
    const reference = searchParams.get("reference");
    const formToSubmit = _.assign(lastForm, form, { reference });
    setCreateStatus("loading");
    api.createMedicalDocument(basePath, formToSubmit)
    .tap(setCreatedMedicalDocument)
    .catch(error => {
      setCreateStatus("error");
      setError(error)
    });
  }, [form]);

  const isLoading = createStatus === "loading";
  const failed = createStatus === "error";
  const errorMessage = _.get(error, "response.data.message");

  return (
    <Grid item md={4} xs={12} width="100%">
      <Stack display="flex" flexDirection="column" alignItems="center" spacing="2rem" p="2rem">
        <Box>Nueva {name}</Box>
        <Stack display="flex" flexDirection="column" alignItems="center" spacing="2rem" width="100%">
          <div style={{ width: "100%" }}>
            {
              !createdMedicalDocument?
              <StepWizard transitions={transitionsOptions}>
                <PatientForm onFinish={onFinish} />
                <MedicineForm onFinish={onFinish} fullForm={form} />
                <DiagnosisForm onFinish={onFinish} />
                <DateForm onFinish={create} isLoading={isLoading} />
              </StepWizard>
              : <Exporter medicalDocument={createdMedicalDocument} onClose={() => navigate("/")} />
            }
          </div>
        </Stack>
        <ErrorAlert
          show={failed}
          onClose={onAlertClose}
          message={`No se pudo crear la ${name} ${errorMessage ? `(${errorMessage})` : ''}`}
        />
      </Stack>
    </Grid>
  );
};

export default NewMedicalDocument;