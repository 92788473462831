import { useCallback, useEffect, useState } from "react";
import { Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import MUIDataTable, { debounceSearchRender } from "mui-datatables";
import ManualInput from "../ManualInput";
import vademecum from "./vademecum.json";
import _ from "lodash";
import SelectedMedicine from "./SelectedMedicine";

const data = _.map(vademecum, ({ marcaComercial, principioActivo, presentaciones }) => ([ principioActivo, marcaComercial, presentaciones ]));

const PickedMedicine = ({ medicine: { principioActivo, marcaComercial } }) => (
  <Typography>{principioActivo} {marcaComercial}</Typography>
);

const defaultPresentation = { name: null, regNo: null };

const quantityWithWord = [
  { quantity: 1, text: 'una' },
  { quantity: 2, text: 'dos' },
  { quantity: 3, text: 'tres' },
  { quantity: 4, text: 'cuatro' },
  { quantity: 5, text: 'cinco' },
  { quantity: 6, text: 'seis' },
  { quantity: 7, text: 'siete' },
  { quantity: 8, text: 'ocho' },
  { quantity: 9, text: 'nueve' },
  { quantity: 10, text: 'diez' }
];

const BoxQuantityPicker = ({ medicine, presentation, onQuantityPicked }) => {
  return (
    <>
      <DialogTitle>Cantidad de cajas</DialogTitle>
      <PickedMedicine medicine={medicine} />
      <Typography>{presentation.name}</Typography>
      <List sx={{ pt: 0 }}>
        {
          _.map(quantityWithWord, (boxQuantity) => {
            const { quantity } = boxQuantity;
            return (
              <ListItem disableGutters key={quantity}>
                <ListItemButton onClick={() => onQuantityPicked(boxQuantity)} key={quantity}>
                  <ListItemText primary={quantity} />
                </ListItemButton>
              </ListItem>
            );
          })
        }
      </List>
    </>
  );
};

const PresentationPicker = ({ form: { setValue, clearErrors }, name, medicineIndex, setMedicineIndex }) => {
  const [open, setOpen] = useState(false);
  const [presentation, setPresentation] = useState(defaultPresentation);
  
  useEffect(() => {
    if (!_.isNumber(medicineIndex)) {
      setOpen(false);
      return;
    }

    setOpen(true);
    
    if (_.size(vademecum[medicineIndex].presentaciones) == 1)
      setPresentation(_.first(medicine.presentaciones));
  }, [medicineIndex]);

  const medicine = vademecum[medicineIndex] || {};
  const clearPickedMedicine = () => {
    setMedicineIndex(null);
    setPresentation(defaultPresentation);
  };
  
  const onQuantityPicked = useCallback(({ quantity, text: quantityText }) => {
    const { requiereDuplicado, marcaComercial, principioActivo } = medicine;
    const { name: presentationName, regNo } = presentation;
    const medicineWithPresentation = `${principioActivo} (${marcaComercial}) ${presentationName} (${quantity} = ${quantityText} caja${quantity > 1 ? 's' : ''})`;
    setValue(`${name}.text`, medicineWithPresentation);
    setValue(`${name}.externalId`, regNo);
    setValue(`${name}.requiresDuplicate`, requiereDuplicado);
    setValue(`${name}.quantity`, quantity);
    setOpen(false);
    clearPickedMedicine();
    clearErrors(name);
  }, [presentation, medicine]);

  return (
    <Dialog onClose={clearPickedMedicine} open={open} PaperProps={{ style: { paddingInline: "1rem" } }}>
      {
        !presentation.name ?
          <>
            <DialogTitle>Presentación</DialogTitle>
            <PickedMedicine medicine={medicine} />
            <List sx={{ pt: 0 }}>
            {
              _.map(medicine.presentaciones, presentacion => (
                <ListItem disableGutters key={presentacion.regNo}>
                  <ListItemButton onClick={() => setPresentation(presentacion)}>
                    <ListItemText primary={presentacion.name} />
                  </ListItemButton>
                </ListItem>
              ))
            }
            </List>
          </>
        : <BoxQuantityPicker medicine={medicine} presentation={presentation} onQuantityPicked={onQuantityPicked}/>
      }
    </Dialog>
  );
};

const columns = [
  "Principio activo",
  "Marca comercial"
];

const toComparableText = text => _.deburr(text).toLowerCase();

const VademecumInput = props => {
  const [medicineIndex, setMedicineIndex] = useState(null);
  const { form, name } = props;
  //TODO improve with useFormState?
  const selectedMedicine = form.getValues(`${name}.text`);
  const options = {
    search: true,
    searchOpen: true,
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    responsive: "standard",
    textLabels: {
      body: {
        noMatch: "No hay medicamentos que coincidan con la búsqueda",
      },
      pagination: {
        rowsPerPage: "Filas por página",
      }
    },
    sortOrder: {
      name: "Principio activo",
      direction: "asc"
    },
    selectableRows: "none",
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20],
    onRowClick: (rowData, rowMeta) => {
      setMedicineIndex(rowMeta.dataIndex);
    },
    customSearch: (searchQuery, currentRow) => {
      return _.some(currentRow, value => _.includes(toComparableText(value), toComparableText(searchQuery)));
    },
    customSearchRender: debounceSearchRender(50),
  };

  return (
    <>
      {
        !selectedMedicine && (
        <>
          <MUIDataTable
            title={"Medicamentos"}
            data={data}
            columns={columns}
            options={options}
          />
          <PresentationPicker {...props} medicineIndex={medicineIndex} setMedicineIndex={setMedicineIndex} />
        </>
        )
      }
      {selectedMedicine && <SelectedMedicine {...props} />}
      <input type="hidden" name={`${name}`.quantity} />
      <input type="hidden" name={`${name}`.externalId} />
      <input type="hidden" name={`${name}`.requiresDuplicate} />
    </>
  );
};

export default VademecumInput;