import { useCallback } from "react";
import { Dialog, DialogContent, DialogActions, Button } from "@mui/material";

const ActionValidatorDialog = ({ isVisible, setIsVisible, message, onConfirm }) => { 
  const hide = useCallback(() =>
    setIsVisible(false)
  , [setIsVisible]);
  const onAbort = useCallback(() => {
    hide();
  }, [hide]);

  const handleGoForward = useCallback(() => {
    onConfirm();
    hide();
  }, [onConfirm, hide]);

  return (
    <Dialog open={isVisible} fullWidth>
      <DialogContent>
        {message}
      </DialogContent>
      <DialogActions>
        <Button onClick={onAbort}>
          No
        </Button>
        <Button onClick={handleGoForward}>
          Si
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ActionValidatorDialog;