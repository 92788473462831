import * as yup from "yup";
import _ from "lodash";

const picture = yup.mixed()
  .default("")
  .test("required", "", (file) => {
    return file;
  })
  .test("required", "El archivo debe ser una imagen", (file) => {
    return !file || file && _.includes(file.type, "image");
  });

export default picture;