import { createTheme } from "@mui/material";

export default createTheme({
  palette: {
    primary: { main: "#4e6b78", contrastText: "#FFF" },
    success: { main: "#4BB543", contrastText: "#FFF" },
    error: { main: "#B00020", contrastText: "#FFF" },
    dark: { main: "#455A64" }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 28,
        },
      }, 
    }, 
  },
  typography: {
    "fontFamily": `"Montserrat", sans-serif`,
    "fontSize": 15,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
   }
});