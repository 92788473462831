import { Stack, Typography } from "@mui/material";
import { useAuth } from "../../auth/context";

const DoctorInfo = () => {
  const { user } = useAuth();
  const { email, name, surname, documentNumber, specialty, title, licenseType, licenseNumber, address, province, workPhone } = user;

  return (
    <Stack direction={{ xs: "column", md: "row" }} width="100%" justifyContent="space-between" spacing="1rem">
      <Stack spacing="1rem">
        <Typography>Nombre completo: {name} {surname}</Typography>
        <Typography>Título: {title}</Typography>
        <Typography>Especialidad: {specialty}</Typography>
        <Typography>Licencia: {licenseNumber} ({licenseType})</Typography>
        <Typography>DNI: {documentNumber}</Typography>
      </Stack>
      <Stack spacing="1rem">
        <Typography>Email: {email}</Typography>
        <Typography>Teléfono: {workPhone}</Typography>
        <Typography>Dirección: {address}</Typography>
        {province && <Typography>Provincia: {province}</Typography>}
      </Stack>
    </Stack>
  );
};

export default DoctorInfo;