import { NumberField } from "../../../../utils/form";

const DocumentNumberInput = ({ form: { control } }) => (
  <NumberField
    control={control}
    fullWidth
    name="documentNumber"
    label="DNI"
    variant="outlined"
    rules={{ required: true, pattern: { value: /^\d{7,8}$/ } }}
  />
);

export default DocumentNumberInput;