import _ from "lodash";
import BaseApi from "./baseApi";

class UrlShortenerApi extends BaseApi {
  constructor() {
    super("https://api.shrtco.de/v2");
  }

  shorten(url) {
    return this.get(`/shorten?url=${url}`)
    .then(response => _.get(response, "result.full_short_link"));
  }
}

export default UrlShortenerApi;