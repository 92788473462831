import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import { Phone, Address, Signature, Email } from "../utils/user/form";
import { useAuth } from "../../auth/context";
import Legend from "./Legend";

const Fields = ({ form, ...props }) => {
  const { user } = useAuth();
  const { profile } = user;
  const healthCenter = profile.healthCenter;
  
  return (
    <Stack spacing="2rem">
      <Typography variant="h5">Datos para las prescripciones</Typography>
      <Legend form={form} />
      {_.isEmpty(healthCenter) &&
        <>
          <Email form={form} />
          <Phone form={form} name="phone" />
          <Address form={form} />
        </>
      }
      <Signature form={form} isLoading={props.signatureLoading} />
    </Stack>
  )
};

export default Fields;