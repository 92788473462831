import { Button } from "@mui/material";
import { InstallMobile } from '@mui/icons-material';
import useIsMobile from "./hooks/useIsMobile";
import { useEvents } from "./context/eventsProxy/context";

const InstallPwaButton = () => {
  const { promptInstall } = useEvents();
  const isMobile = useIsMobile();

  const onClick = evt => {
    evt.preventDefault();
    promptInstall.prompt();
  };
  if (!promptInstall || !isMobile) {
    return null;
  }
  return (
    <Button onClick={onClick} color="success" style={{ textTransform: "none", fontSize: "1.5rem" }}>
      Instalá la app <InstallMobile />
    </Button>
  );
};

export default InstallPwaButton;