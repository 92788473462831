import _ from "lodash"; 
import { useNavigate, useSearchParams } from "react-router-dom";

export default () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (path, options) => {
    const searchParamsString = searchParams.toString();
    const pathWithSearch = (_.isEmpty(searchParamsString) || _.isNumber(path)) ? path : `${path}?${searchParamsString}`;

    return navigate(pathWithSearch, options);
  }
};