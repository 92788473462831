
import { Button, FormHelperText, Stack, Typography } from "@mui/material";
import _ from "lodash";
import SignatureCanvas from 'react-signature-canvas'
import { useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import { LoadingSpinner } from "../../../../utils";

const ControlledSignatureCanvas = ({ form: { resetField }, field: { value, onChange }, fieldState: { error }, helperText, isLoading }) => {
  const canvasDrawRef = useRef();

  useEffect(() => {
    if (value === canvasDrawRef.current.toDataURL()) return;
    canvasDrawRef.current.fromDataURL(value, { ratio: 1 });
  }, [value]);

  const onCanvasChanged = () => {
    const signatureUri = canvasDrawRef.current.toDataURL();
    onChange(signatureUri);
  };

  if (isLoading) return <LoadingSpinner isLoading />;
  
  return (
    <Stack spacing="1rem">
      <SignatureCanvas
        canvasProps={{
          width: 300,
          height: 200,
          className: 'sigCanvas',
          style: {
            boxShadow: "rgb(50 50 93 / 25%) 0px 0px 15px 1px, rgb(0 0 0 / 30%) 0px 8px 16px -8px"
          },
        }}
        ref={canvasDrawRef}
        onEnd={onCanvasChanged}
      />
      {helperText && <FormHelperText error={!_.isEmpty(error)} style={{ margin: "3px 14px 0 14px" }}>{helperText}</FormHelperText>}
      <Button onClick={() => canvasDrawRef.current.clear() || resetField("signature", { defaultValue: "" })} variant="contained">
        Limpiar
      </Button>
      {!_.isEmpty(error) && error.message}
    </Stack>
  )
};

const DrawInput = (props) => {
  const { form: { control }, name, required } = props;
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required
      }}
      render={renderProps => <ControlledSignatureCanvas {...renderProps} {...props} />}
    />
  )
};

const SignatureInput = props => {
  return <>
    <Typography>Firma</Typography>
    <div style={{ marginLeft: "auto", marginRight: "auto" }}>
      <DrawInput {...props} name="signature" helperText="Dibujá tu firma acá" required />
    </div>
  </>
};

export default SignatureInput;