import { useSearchParams } from "react-router-dom";
import { Specialty } from "../../utils/user/form";
import _ from "lodash";
import { useEffect } from "react";

const RegisterSpecialty = props => {
  const [searchParams] = useSearchParams();
  const specialty = searchParams.get("specialty");

  useEffect(() => {
    const { form } = props;
    const { setValue, getValues } = form;
    const currentSpecialty = getValues("specialty");
    if (currentSpecialty || !specialty) return;

    setValue("specialty", specialty);
  }, []);

  const disabled = !_.isEmpty(specialty);
  return <Specialty {...props} disabled={disabled} />;
};

export default RegisterSpecialty;