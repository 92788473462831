
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../auth/context";
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ErrorAlert } from "../../utils";
import { EmailInput, TextField } from "../../utils/form";
import { Link } from "react-router-dom";
import _ from "lodash";
import Logo from "../../utils/Logo";

const TermsAndConditionsLabel = () => {
  return (
    <Typography align="center" fontSize="13px">
      Al iniciar sesión, aceptás nuestros <Typography color="primary" component={Link} to="/termsAndConditions" target="_blank" fontSize=".8rem">términos y condiciones</Typography>.
    </Typography>
  );
};

const invalidCredentialsMessage = "Credenciales inválidas";
const inactiveAccountMessage = "Tu cuenta aún no está activa. Si ya pasaron más de 24hs hábiles desde que te registraste, contactanos a consultas@recetario.com.ar";

const Login = () => {
  const form = useForm({
    defaultValues: {
      email: '',
      password: ''
    }
  });
  const [loginStatus, setLoginStatus] = useState(null);
  const [error, setError] = useState();
  const auth = useAuth();

  const { handleSubmit, setValue, control } = form;

  const login = ({ email, password }) => {
    setLoginStatus("loading")
    auth.signin(email, password)
    .catch(error => {
      setError(error);
      setLoginStatus(null);
      setValue("password", "");
    });
  };

  const onAlertClose = () => setError(null);

  const isLoading = loginStatus === "loading";
  const failed = !_.isEmpty(error);
  const isInactiveError = _.chain(error).get("response.data.message").includes("active").value();
  const message = isInactiveError ? inactiveAccountMessage : invalidCredentialsMessage;

  return (
    <Stack display="flex" flexDirection="column" spacing="2rem" boxSizing="border-box" width="100%" height="100%">
      <form onSubmit={handleSubmit(login)} autoComplete="off" style={{ height: "100%", width: "100%" }}>
        <Paper style={{ height: "100%", display: "flex", alignItems: "center", backgroundPosition: "top", backgroundRepeat: "no-repeat", backgroundImage: "url(https://res.cloudinary.com/dw86qy8xu/image/upload/v1694744779/loginSplash_syhrc1.jpg)", backgroundSize: "cover" }}>
          <Grid item xs={12} sm={8} md={6} lg={4} marginX="auto" boxSizing="border-box" p="1rem">
            <Box p="1rem" boxSizing="border-box" borderRadius="16px" boxShadow="0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px rgba(0, 0, 0, 0.15)" bgcolor="#FAFAFA">
              <Stack direction="column" alignItems="center" p="1rem" spacing="2rem">
                <Logo />
                <Stack width="100%" spacing="1rem" pt="1.5rem">
                  <EmailInput form={form} style={{ width: "100%" }} required />
                  <TextField control={control} name="password" type="password" label="Contraseña" variant="outlined" style={{ width: "100%" }} required />
                </Stack>
                <LoadingButton type="submit" variant="contained" sx={{ fontSize: "1rem", textTransform: "none", width: "100%" }} loading={isLoading}>
                  Iniciar sesión
                </LoadingButton>
                <Stack spacing={{ xs: "2rem", sm: "4rem" }} direction="row">
                  <Typography color="primary" component={Link} to="/register" fontSize=".9rem">Registrarme</Typography>
                  <Typography color="primary" component={Link} to="/passwordRecovery/request" fontSize=".9rem">Olvidé mi contraseña</Typography>
                </Stack>
                <TermsAndConditionsLabel />
              </Stack>
            </Box>
          </Grid>
        </Paper>
      </form>
      <ErrorAlert
        show={failed}
        onClose={onAlertClose}
        message={message}
      />
    </Stack>
  );
};

export default Login;