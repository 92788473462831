import { CircularProgress } from '@mui/material';

const LoadingSpinner = ({ isLoading, absolute }) => (
  isLoading &&
    <CircularProgress
      size={16}
      sx={{
        top: '50%',
        position: absolute && "absolute"
      }}
  /> 
);

export default LoadingSpinner;