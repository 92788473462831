import _ from "lodash";
import BaseApi from "./baseApi";

class BearerApi extends BaseApi {
  constructor(baseUrl, token) {
    super(baseUrl);
    this.token = token;
  }

  _options(...params) {
    const baseOptions = super._options(...params);
    return _.merge(baseOptions, {
      headers: {
        "Authorization": `Bearer ${this.token}`
      }
    });
  }
}

export default BearerApi;