import { Stack, Typography } from "@mui/material";
import { useAuth } from "../../auth/context";
import Logo from "./Logo";
import InstallPwaButton from "../../utils/InstallPwaButton";
import NewPatientButton from "./NewPatientButton";
import { NewOrderButton, NewPrescriptionButton, TrialsButton } from "./mainOptions";
import TrialsVideo from "./TrialsVideo";
import NewFeatureMessage from "../../utils/NewFeatureMessage";

const Home = () => {
  const { user } = useAuth();

  return (
    <Stack flexDirection="column" justifyContent="space-between" height="100%">
      <Stack display="flex" flexDirection="column" justifyContent="center" spacing={4} p="2rem">
        <Logo />
        <Typography textAlign="center" variant="h5">
          Bienvenid{user.title == "Dr" ? "o" : "a"}, {user.name}
        </Typography>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
          <NewPrescriptionButton />
          <NewOrderButton />
          <TrialsButton />
        </Stack>
        <NewFeatureMessage />
        <TrialsVideo />
      </Stack>
      <NewPatientButton />
      <InstallPwaButton />
    </Stack>
  );
};

export default Home;
