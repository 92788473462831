import { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { MethodPicker } from "../../../../utils/form";
import ManualInput from "./ManualInput";
import _ from "lodash";
import NavigationBar from "../../../../utils/wizard/navigation";
import { useForm } from "react-hook-form";
import TemplatesMenu from "../../../utils/medicalDocument/templates/menu";
import TemplateContentPicker from "../../../utils/medicalDocument/templates/ContentPicker";
import defaultTemplates from "./defaultTemplates";
import { useApi } from "../../../../utils/hooks";

const options = [
  { label: "Manual", value: "manual" },
  { label: "Laboratorio", value: "laboratory", disabled: true },
  { label: "Estudio por imágenes", value: "diagnosticImaging", disabled: true },
];

const templateType = "order";
const defaultMedicine = 'Solicito:\n';
const medicineFieldName = "medicine";
const MedicineForm = ({ onFinish, ...props }) => {
  const form = useForm({ defaultValues: { medicine: defaultMedicine } });
  const [method, setMethod] = useState("manual");
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const { handleSubmit } = form;
  const api = useApi();

  const onSubmit = (medicine) => {
    onFinish(medicine);
    props.nextStep();
  };

  useEffect(() => {
    form.resetField(medicineFieldName);
  }, [method]);

  useEffect(() => {
    api.templates(templateType)
    .then(templates => _.concat(templates, defaultTemplates))
    .tap(setTemplates);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Typography variant="h5" gutterBottom>Solicitud</Typography>
        <Stack spacing="2rem">
          <MethodPicker method={method} setMethod={setMethod} options={options} />
          <TemplatesMenu
            templates={templates}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            defaultContent={defaultMedicine}
            type={templateType}
          />
          {
            method === "manual" && <ManualInput form={form} />
          }
          <TemplateContentPicker
            selectedTemplate={selectedTemplate}
            form={form}
            fieldName={medicineFieldName}
            defaultContent={defaultMedicine}
          />
        </Stack>
      <NavigationBar step={2} {...props}/>
    </form>
  );
};

export default MedicineForm;