import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Alert, ErrorAlert } from "../../utils";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { password as passwordValidations } from "../../utils/form/validations";
import { Passwords as PasswordFields } from "../utils/user/form";
import Api from "../../apis/api";

const formSchema = yup.object().shape(passwordValidations)

const ChangePassword = () => {
  const form = useForm({
    defaultValues: formSchema.cast(),
    resolver: yupResolver(formSchema)
  });
  const [ loginStatus, setLoginStatus ] = useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { handleSubmit } = form;
  const api = new Api();

  const securitySignature = searchParams.get("securitySignature");

  useEffect(() => {
    if (!securitySignature)
      return navigate('/login');
  }, []);

  const requestPasswordRecovery = ({ password }) => {
    setLoginStatus("loading");
    api.changePassword({ password, securitySignature })
    .then(() => {
      setLoginStatus("success")
      setTimeout(() => {
        navigate('/login')
      }, 3500);
    })
    .catch(() => {
      setLoginStatus("error");
    });
  };

  const onAlertClose = () => setLoginStatus(null);

  const isLoading = loginStatus === "loading";
  const failed = loginStatus === "error";
  const succeeded = loginStatus === "success";

  return (
    <Stack display="flex" flexDirection="column" alignItems="center" spacing="2rem" pt="2rem">
      <Box>
        Cambiá tu contraseña
      </Box>
      <form onSubmit={handleSubmit(requestPasswordRecovery)} autoComplete="off">
        <Stack display="flex" flexDirection="column" alignItems="center" pt="3rem" spacing="2rem">
          <PasswordFields form={form} label="Nueva contraseña" />
          <LoadingButton type="submit" variant="contained" sx={{paddingX: "6rem"}} loading={isLoading} disabled={isLoading || succeeded}>
            Cambiar contraseña
          </LoadingButton>
        </Stack>
      </form>
      <ErrorAlert
        show={failed}
        onClose={onAlertClose}
        message="No pudimos cambiar tu contraseña. Intentá de nuevo."
      />
      <Alert
        severity="success"
        message="Listo! Cambiaste tu contraseña. Te redirigimos al inicio de sesión..."
        show={succeeded}
      />
    </Stack>
  );
};

export default ChangePassword;