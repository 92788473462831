import { useState } from "react";
import { Alert } from ".";

const NewFeatureMessage = () => {
  const [show, setShow] = useState(false);

  return <Alert
    severity="success"
    message="¡Nueva funcionalidad! Envío de recetas y órdenes sin usar tu Whatsapp personal"
    show={show}
    onClose={() => setShow(false)}
  />
};

export default NewFeatureMessage;