import { Stack } from '@mui/material';
import YouTube from 'react-youtube';
import useIsMobile from '../../utils/hooks/useIsMobile';
import { useAuth } from '../../auth/context';

const videoId = "S-MLAYWtxAo";

const TrialsVideo = () => {
  const isMobile = useIsMobile();
  const { user } = useAuth();
  
  if (!user.hasScope("trials")) return null;

  const opts = {
    width: isMobile ? 300 : 640,
    playerVars: {
      autoplay: 1,
      mute: 1,
      loop: 1,
      playlist: videoId
    },
  };

  return (
    <Stack alignItems="center">
      <YouTube videoId={videoId} opts={opts} />
    </Stack>
  );
};

export default TrialsVideo;